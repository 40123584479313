import { useState, useRef, useCallback, useEffect } from 'react';
import { fabric } from 'fabric';
import { API } from 'api';
import * as types from 'constants/actionTypes';
import { ExtraFieldsForEditor } from 'constants/canvas';
import { BookFlipType } from 'constants/flipTypes';
import { PainterMode } from 'constants/painterModes';
import { CustomizeControlType } from 'constants/customizeControlType';
import { PainterType, LineType } from 'constants/painterTypes';
import { SideToolDirection } from 'constants/sideToolContents';
import { FillType } from 'constants/fillTypes';
import { StampMap } from 'constants/stampTypes';
import { useStore, StoreTypes } from 'context';
import { useUpdateAnnotation, useMarkAnnotationSynced, useReadAnnotations } from 'customHooks/db'
import { useWindowSize } from 'customHooks/windowSize';
import { EventBus, EventBusType } from 'events/EventBus';
import { CanvasEvent, ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import { short_uuid } from 'util/uuid';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import { CanvasSVGObjectContentType } from 'constants/canvasSVGObjectContentTypes';
import { ReaderToolType } from 'constants/ReaderTools';
import { Roles } from 'constants/role';
import { MeasureToolType, PainterToolType } from 'constants/painterTypes';
import { storeUndoAnnotation, escapeEmptyItext } from 'util/checkUndoAnnotation';
import { useReadTldrawAnnotations } from './tldrawDB';

let canvasReanderTime;

const angleAreaCondition = (angle) => {
    let parseAngle = 0;
    const baseAngle = 45;
    const area = Math.floor(angle / 90);
    const remainder = angle % 90;

    switch (area) {
        case 1:
            if (remainder <= baseAngle) {
                parseAngle = 0;
            } else {
                parseAngle = 90;
            }
            break;
        case 2:
            if (remainder <= baseAngle) {
                parseAngle = 90;
            } else {
                parseAngle = 180;
            }
            break;
        case 3:
            if (remainder <= baseAngle) {
                parseAngle = 180;
            } else {
                parseAngle = 270;
            }
            break;
        default:
            if (remainder <= baseAngle) {
                parseAngle = 270;
            } else {
                parseAngle = 0;
            }
            break;
    }
    return parseAngle
}

const addNinety = (angle) => {
    let addAngle = angle + 90;
    if (addAngle >= 360) {
        addAngle = addAngle - 360;
    }
    return addAngle;
}

const angleControl = (eventData, transform) => {
    const target = transform.target;
    const canvas = target.canvas;
    const floorAngle = parseInt(target.angle, 10);
    const isInteger = floorAngle === 0 || floorAngle === 90 || floorAngle === 180 || floorAngle === 270 || floorAngle === 360
    const angle = angleAreaCondition(floorAngle);

    target.rotate(addNinety(isInteger ? floorAngle : angle)).setCoords();
    canvas.requestRenderAll();
}

const flipControl = (eventData, transform) => {
    const target = transform.target;
    const canvas = target.canvas;
    target.set('flipX', !target.flipX);
    canvas.requestRenderAll();
}

export const useCustomizeControls = () => {
    const [{ scale }] = useStore(StoreTypes.reader);
    const deleteIcon = 'assets/measureTools/icon-close.svg';
    const spinIcon = 'assets/measureTools/icon-spin.svg';
    const flipIcon = 'assets/measureTools/icon-flip.svg';
    const rotationIcon = 'assets/measureTools/icon-rotate.svg';
    const scaleRIcon = 'assets/measureTools/icon-scale.svg';
    const scaleLIcon = 'assets/measureTools/icon-scaleL.svg';

    const deleteObject = useCallback((eventData, transform) => {
        const target = transform.target;
        const canvas = target.canvas;
        canvas.remove(target);
        canvas.requestRenderAll();
    }, [])

    const rotationImg = document.createElement('img');
    rotationImg.src = rotationIcon;
    const scaleRImg = document.createElement('img');
    scaleRImg.src = scaleRIcon;
    const scaleLImg = document.createElement('img');
    scaleLImg.src = scaleLIcon;

    // 刪除
    const deleteImg = document.createElement('img');
    deleteImg.src = deleteIcon;
    const deleteRenderIcon = useCallback((ctx, left, top, styleOverride, fabricObject) => {
        const size = fabricObject.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(deleteImg, -size, -size, size * 2, size * 2);
        ctx.restore();
    }, [deleteImg])

    // flip
    const flipControlImg = document.createElement('img');
    flipControlImg.src = flipIcon;
    const flipControlRenderIcon = useCallback((ctx, left, top, styleOverride, fabricObject) => {
        const size = fabricObject.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(flipControlImg, -size, -size, size * 2, size * 2);
        ctx.restore();
    }, [flipControlImg]);

    // 90度icon
    const angelControlImg = document.createElement('img');
    angelControlImg.src = spinIcon;
    const angleControlRenderIcon = useCallback((ctx, left, top, styleOverride, fabricObject) => {
        const size = fabricObject.cornerSize;
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        ctx.drawImage(angelControlImg, -size, -size, size * 2, size * 2);
        ctx.restore();
    }, [angelControlImg]);

    // 角度
    const renderAngleNumber = useCallback((ctx, left, top, styleOverride, fabricObject) => {
        const fabricObjectAngle = parseInt(fabricObject.angle, 10);
        ctx.save();
        ctx.translate(left, top);
        //ctx.rotate(fabric.util.degreesToRadians(fabricObjectAngle));
        ctx.font = "20px Arial";
        ctx.fillStyle = "#ff0000";
        ctx.fillText(`${fabricObjectAngle}°`, 0, 0);
        ctx.restore();
    }, []);

    // 中心點
    const renderCenterPoint = useCallback((ctx, left, top, styleOverride, fabricObject) => {
        // const { width, height, scaleX } = fabricObject
        // let newW = width * scaleX
        // let newH = height * scaleX
        // const fabricObjectAngle = parseInt(fabricObject.angle, 10);
        // ctx.save();
        // ctx.translate(left, top);
        // ctx.rotate(fabric.util.degreesToRadians(fabricObjectAngle));
        // ctx.beginPath();
        // switch (fabricObject.measureTools) {
        //     case MeasureToolType.Protractor:
        //         ctx.arc(0,newH/2,2,0,2*Math.PI);
        //         break;
        //     case MeasureToolType.ShortRuler:
        //     case MeasureToolType.LongRuler:
        //         ctx.arc(-newW/2,-newH/2,2,0,2*Math.PI);
        //         break;
        //     case MeasureToolType.IsoscelesTriangle:
        //         ctx.arc(0,newH/2,2,0,2*Math.PI);
        //         break;
        //     case MeasureToolType.RightTriangle:
        //         ctx.arc(-newW/2,-newH/2,2,0,2*Math.PI);
        //         break;

        //     default:
        //         ctx.arc(0,0,2,0,2*Math.PI);
        //         break;
        // }

        // ctx.strokeStyle = "#f00";
        // ctx.fillStyle = "#f00";
        // ctx.fill();
        // ctx.restore();

    }, []);

    const setMeasureOriginXY = (image) => {
        let measureInfo;
        switch (image.measureTools) {
            case MeasureToolType.Protractor:
            case MeasureToolType.ShortRuler:
            case MeasureToolType.LongRuler:
                measureInfo = {
                    originX: "center",
                    originY: "bottom",
                    centeredRotation: false,
                    lockScalingFlip: true
                }
                break;
            case MeasureToolType.IsoscelesTriangle:
                measureInfo = {
                    originX: "center",
                    originY: "bottom",
                    centeredRotation: false,
                    lockScalingFlip: true
                }
                break;
            case MeasureToolType.RightTriangle:
                measureInfo = {
                    originX: "left",
                    originY: "top",
                    centeredRotation: false,
                    lockScalingFlip: true
                }
                break;

            default:
                break;
        }
        image.set(measureInfo)
        image.setControlsVisibility({
            bl: true, // 左下
            br: true, // 右下
            mb: false, // 下中
            ml: false, // 中左
            mr: false, // 中右
            mt: false, // 上中
            tl: true, // 上左
            tr: true, // 上右
            mtr: true // 旋轉控制鍵
        })
    }

    const mtr1Render = useCallback((ctx, left, top, styleOverride, fabricObject) => {
        const { width, height, scaleX, measureTools } = fabricObject
        let newW = width * scaleX
        let newH = height * scaleX
        let x = 0
        let y = 0
        var size = 28 * (scale / 1.7);
        ctx.save();
        ctx.translate(left, top);
        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
        x = -size / 2
        y = -size / 2 - 40
        ctx.drawImage(rotationImg, x, y, size, size);
        ctx.restore();

        switch (measureTools) {
            case MeasureToolType.Protractor:
            case MeasureToolType.IsoscelesTriangle:
                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                x = -newW / 2
                y = -size / 2
                ctx.drawImage(scaleLImg, x * scale - size / 2, y, size, size);
                ctx.restore();
                break;

            default:
                break;
        }

        switch (measureTools) {
            case MeasureToolType.Protractor:
            case MeasureToolType.IsoscelesTriangle:
            case MeasureToolType.RightTriangle:

                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                x = newW / 2
                y = -size / 2
                ctx.drawImage(scaleRImg, x * scale - size / 2, y, size, size);
                ctx.restore();
                break;

            default:
                break;
        }


        switch (measureTools) {
            case MeasureToolType.ShortRuler:
            case MeasureToolType.LongRuler:
            case MeasureToolType.IsoscelesTriangle:
            case MeasureToolType.RightTriangle:
                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                x = newW / 2
                y = newH
                ctx.drawImage(scaleLImg, x * scale - size / 2, y * scale - size / 2, size, size);
                ctx.restore();
                break;

            default:

                break;
        }


        switch (measureTools) {
            case MeasureToolType.ShortRuler:
            case MeasureToolType.LongRuler:
            case MeasureToolType.IsoscelesTriangle:
            case MeasureToolType.RightTriangle:
                ctx.save();
                ctx.translate(left, top);
                ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                x = -newW / 2
                y = newH
                ctx.drawImage(scaleRImg, x * scale - size / 2, y * scale - size / 2, size, size);
                ctx.restore();
                break;
            default:

                break;
        }


    }, [rotationImg, scale, scaleLImg, scaleRImg]);

    const setMeasureControls = useCallback(({ target }) => {
        let position = 0;
        const baseRatio = -18;
        const gap = [1, 3, 5, 7];

        if (target.measureTools !== MeasureToolType.RightTriangle) {
            target.controls.flipControl = new fabric.Control();
        }

        target.controls.removeControl = new fabric.Control({
            x: 0.48,
            y: 0.5,
            offsetX: baseRatio * gap[position],
            offsetY: 24,
            cursorStyle: 'pointer',
            mouseUpHandler: deleteObject,
            render: deleteRenderIcon,
            cornerSize: 48
        });

        position += 1;
        target.controls.angleControl = new fabric.Control({
            x: 0.488,
            y: 0.5,
            offsetX: baseRatio * gap[position],
            offsetY: 24,
            cursorStyle: 'pointer',
            mouseUpHandler: angleControl,
            render: angleControlRenderIcon
        });

        if (
            target.measureTools === MeasureToolType.RightTriangle
        ) {
            position += 1;
            target.controls.flipControl = new fabric.Control({
                x: 0.5,
                y: 0.5,
                offsetX: baseRatio * gap[position],
                offsetY: 24,
                cursorStyle: 'pointer',
                mouseUpHandler: flipControl,
                render: flipControlRenderIcon,
                cornerSize: 48
            });
        }

        position += 1;
        target.controls.angleNumber = new fabric.Control({
            x: 0.5,
            y: 0.5,
            offsetX: baseRatio * gap[position] - 26,
            offsetY: 32,
            cursorStyle: 'pointer',
            render: renderAngleNumber,
            cornerSize: 48
        });

        target.controls.centerPoint = new fabric.Control({
            x: 0,
            y: 0,
            render: renderCenterPoint
        });

        target.controls.mtr1 = new fabric.Control({
            x: 0,
            y: -0.5,
            offsetY: 0,
            render: mtr1Render,
        });

        setMeasureOriginXY(target)

    }, [deleteObject, deleteRenderIcon, angleControlRenderIcon, renderAngleNumber, renderCenterPoint, mtr1Render, flipControlRenderIcon])

    const hideMeasureControls = useCallback(({ target }) => {
        target.controls.removeControl = new fabric.Control();
        target.controls.angleControl = new fabric.Control();
        target.controls.angleNumber = new fabric.Control();
        target.controls.centerPoint = new fabric.Control();
        target.controls.flipControl = new fabric.Control();
        target.controls.mtr1 = new fabric.Control({
            x: 0,
            y: -0.5,
            offsetY: 0
        });
    }, [])

    return {
        setMeasureControls,
        hideMeasureControls,
    }
}


export const useAssignCanvasObjectId = () => {
    const reducers = useStore();
    const [{ canvas }] = reducers[StoreTypes.canvas];

    const assignCanvasObjectId = useCallback(() => {
        canvas.getObjects().forEach(obj => {
            if (!obj.id) {
                obj.id = short_uuid();
            }
        });
    }, [canvas]);

    return assignCanvasObjectId;
};

export const useExportCanvasToJSON = () => {
    const reducers = useStore();
    const [{ canvas, extraFields }, canvasDispatch] = reducers[StoreTypes.canvas];
    const [{ bookId, style, books }] = reducers[StoreTypes.books];
    const [{ pageIndex, isDoublePageMode }] = reducers[StoreTypes.reader];
    const { width, height } = style;
    const book = books.find(book => book.bookId === bookId);
    const { width: bookWidth, height: bookHeight, LRFlip } = book || {};

    const exportCanvasToJSON = useCallback(({ targetPage = (pageIndex * (isDoublePageMode ? 2 : 1)) } = {}) => {
        const originalCanvasJSON = canvas.toJSON(extraFields);
        const leftPageCanvasJSON = canvas.toJSON(extraFields);
        const rightPageCanvasJSON = canvas.toJSON(extraFields);
        leftPageCanvasJSON.objects = [];
        rightPageCanvasJSON.objects = [];

        const ratio = bookWidth / bookHeight;
        let offsetX = [0, 0];
        let offsetY = 0;
        let scale = 1.0;
        let factor = isDoublePageMode ? 2 : 1;
        if (bookWidth * factor / bookHeight < width / height) {
            scale = height / bookHeight;
        } else {
            scale = width / (bookWidth * factor);
        }
        if (isDoublePageMode) {
            offsetX[1] = width / 2;
            if (height * ratio < width / 2) {
                offsetX[0] = (width / 2 - height * ratio);
            } else {    // height * ratio > width / 2
                offsetY = (height - (width / 2) / ratio) / 2;
            }
        } else {
            if (height * ratio < width) {
                offsetX[0] = (width - height * ratio) / 2;
            } else {    // height * ratio > width
                offsetY = (height - width / ratio) / 2;
            }
        }

        const shiftPoint = (x, y, offsetX, offsetY, scale, isRightPage) => {
            const newY = y - offsetY;
            let newX = x;
            if (isRightPage) {
                newX -= offsetX[1];
            } else {
                newX -= offsetX[0];
            }
            return [newX / scale, newY / scale];
        };

        originalCanvasJSON.objects.forEach((obj) => {
            let isRightPage = false;
            if (obj.type === 'path') {
                isRightPage = isDoublePageMode && obj.path[0][1] >= offsetX[1];
                obj.path.forEach((p) => {
                    if (p[0] === 'z') return;
                    let fixedOffsetX = isRightPage ? offsetX[1] : offsetX[0];
                    p[1] = (p[1] - fixedOffsetX) / scale;
                    p[2] = (p[2] - offsetY) / scale;
                    if (p[0] === 'Q') {
                        p[3] = (p[3] - fixedOffsetX) / scale;
                        p[4] = (p[4] - offsetY) / scale;
                    }
                });
            } else if (obj.type === 'line') {
                if (isDoublePageMode) {
                    if (obj.left >= offsetX[1]) {
                        isRightPage = true;
                    } else if (obj.x1 > 0 && obj.left + obj.width > offsetX[1]) {
                        isRightPage = true;
                    }
                }
                [obj.x1, obj.y1] = shiftPoint(obj.x1, obj.y1, offsetX, offsetY, scale);
                [obj.x2, obj.y2] = shiftPoint(obj.x2, obj.y2, offsetX, offsetY, scale);
            } else if (obj.type === 'circle') {
                isRightPage = isDoublePageMode && obj.left >= offsetX[1];
                obj.radius /= scale;
            } else if (obj.type === 'group' && obj.extra && obj.extra.stamp) {
                isRightPage = isDoublePageMode && obj.left >= offsetX[1];
                obj.scaleX = scale;
                obj.scaleY = scale;
            } else {
                isRightPage = isDoublePageMode && obj.left >= offsetX[1];
                obj.extra = { ...(obj.extra || {}), originalScale: [obj.scaleX / scale, obj.scaleY / scale] };
            }

            [obj.left, obj.top] = shiftPoint(obj.left, obj.top, offsetX, offsetY, scale, isRightPage);
            isRightPage ? rightPageCanvasJSON.objects.push(obj) : leftPageCanvasJSON.objects.push(obj);
        });

        let annotation = {};
        if (!isDoublePageMode) {
            annotation[targetPage] = JSON.stringify(leftPageCanvasJSON);
        } else if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
            annotation[targetPage] = JSON.stringify(leftPageCanvasJSON);
            annotation[targetPage + 1] = JSON.stringify(rightPageCanvasJSON);
        } else {
            annotation[targetPage] = JSON.stringify(rightPageCanvasJSON);
            annotation[targetPage + 1] = JSON.stringify(leftPageCanvasJSON);
        }

        return annotation;
    }, [LRFlip, bookHeight, bookWidth, canvas, extraFields, height, isDoublePageMode, pageIndex, width]);

    const exportFilterMeasureToolCanvasToJSON = useCallback(({ targetPage = (pageIndex * (isDoublePageMode ? 2 : 1)) } = {}) => {
        const originalCanvasJSON = canvas.toJSON(extraFields);
        const leftPageCanvasJSON = canvas.toJSON(extraFields);
        const rightPageCanvasJSON = canvas.toJSON(extraFields);
        leftPageCanvasJSON.objects = [];
        rightPageCanvasJSON.objects = [];

        const ratio = bookWidth / bookHeight;
        let offsetX = [0, 0];
        let offsetY = 0;
        let scale = 1.0;
        let factor = isDoublePageMode ? 2 : 1;
        if (bookWidth * factor / bookHeight < width / height) {
            scale = height / bookHeight;
        } else {
            scale = width / (bookWidth * factor);
        }
        if (isDoublePageMode) {
            offsetX[1] = width / 2;
            if (height * ratio < width / 2) {
                offsetX[0] = (width / 2 - height * ratio);
            } else {    // height * ratio > width / 2
                offsetY = (height - (width / 2) / ratio) / 2;
            }
        } else {
            if (height * ratio < width) {
                offsetX[0] = (width - height * ratio) / 2;
            } else {    // height * ratio > width
                offsetY = (height - width / ratio) / 2;
            }
        }

        const shiftPoint = (x, y, offsetX, offsetY, scale, isRightPage) => {
            const newY = y - offsetY;
            let newX = x;
            if (isRightPage) {
                newX -= offsetX[1];
            } else {
                newX -= offsetX[0];
            }
            return [newX / scale, newY / scale];
        };

        originalCanvasJSON.objects = originalCanvasJSON.objects.filter(object => !object.measureTools);

        originalCanvasJSON.objects.forEach((obj) => {
            let isRightPage = false;
            if (obj.type === 'path') {
                isRightPage = isDoublePageMode && obj.path[0][1] >= offsetX[1];
                obj.path.forEach((p) => {
                    if (p[0] === 'z') return;
                    let fixedOffsetX = isRightPage ? offsetX[1] : offsetX[0];
                    p[1] = (p[1] - fixedOffsetX) / scale;
                    p[2] = (p[2] - offsetY) / scale;
                    if (p[0] === 'Q') {
                        p[3] = (p[3] - fixedOffsetX) / scale;
                        p[4] = (p[4] - offsetY) / scale;
                    }
                });
            } else if (obj.type === 'line') {
                if (isDoublePageMode) {
                    if (obj.left >= offsetX[1]) {
                        isRightPage = true;
                    } else if (obj.x1 > 0 && obj.left + obj.width > offsetX[1]) {
                        isRightPage = true;
                    }
                }
                [obj.x1, obj.y1] = shiftPoint(obj.x1, obj.y1, offsetX, offsetY, scale);
                [obj.x2, obj.y2] = shiftPoint(obj.x2, obj.y2, offsetX, offsetY, scale);
            } else if (obj.type === 'circle') {
                isRightPage = isDoublePageMode && obj.left >= offsetX[1];
                obj.radius /= scale;
            } else if (obj.type === 'group' && obj.extra && obj.extra.stamp) {
                isRightPage = isDoublePageMode && obj.left >= offsetX[1];
                obj.scaleX = scale;
                obj.scaleY = scale;
            } else {
                isRightPage = isDoublePageMode && obj.left >= offsetX[1];
                obj.extra = { ...(obj.extra || {}), originalScale: [obj.scaleX / scale, obj.scaleY / scale] };
            }

            [obj.left, obj.top] = shiftPoint(obj.left, obj.top, offsetX, offsetY, scale, isRightPage);
            isRightPage ? rightPageCanvasJSON.objects.push(obj) : leftPageCanvasJSON.objects.push(obj);
        });

        let annotation = {};
        if (!isDoublePageMode) {
            annotation[targetPage] = JSON.stringify(leftPageCanvasJSON);
        } else if (LRFlip === BookFlipType.LEFT_TO_RIGHT) {
            annotation[targetPage] = JSON.stringify(leftPageCanvasJSON);
            annotation[targetPage + 1] = JSON.stringify(rightPageCanvasJSON);
        } else {
            annotation[targetPage] = JSON.stringify(rightPageCanvasJSON);
            annotation[targetPage + 1] = JSON.stringify(leftPageCanvasJSON);
        }

        return annotation;
    }, [LRFlip, bookHeight, bookWidth, canvas, extraFields, height, isDoublePageMode, pageIndex, width]);

    const exportExtendedContentCanvasToJSON = useCallback(({ id } = {}) => {
        const originalCanvasJSON = canvas.toJSON();
        return JSON.stringify(originalCanvasJSON);
    }, [canvas]);

    return { exportCanvasToJSON, exportExtendedContentCanvasToJSON, exportFilterMeasureToolCanvasToJSON };
}

export const useSaveCanvasJSON = () => {
    const reducers = useStore();
    let [{ undoAnnotations, canvasJSON }, canvasDispatch] = reducers[StoreTypes.canvas];
    const [{ annotationId }] = reducers[StoreTypes.annotation];
    const [{ pageIndex, isDoublePageMode }] = reducers[StoreTypes.reader];
    const [{ style }] = reducers[StoreTypes.books];
    const { exportCanvasToJSON, exportFilterMeasureToolCanvasToJSON } = useExportCanvasToJSON();
    const updateAnnotation = useUpdateAnnotation();
    const { readAnnotationById } = useReadAnnotations();

    const decider = useReaderStrategyDecider();
    const strategy = decider.getReaderStrategy();

    const saveCanvasJSON = useCallback(async (undoInfo) => {
        // console.log('here undoInfo', undoInfo)
        // console.log('pageIndex', pageIndex)
        // let targetPage = pageIndex * (isDoublePageMode ? 2 : 1);
        // targetPage = (targetPage % 2 === 0) ? targetPage : targetPage - 1;
        const annotation = undoInfo ? undoInfo.canvasJson : exportCanvasToJSON();
        const exportAnnotation = exportCanvasToJSON();

        canvasDispatch({
            type: types.CANVAS_EXPORT_SVG,
            canvasJSON: exportAnnotation
        });

        // 記錄上一步下一步步驟
        if (!undoInfo) {
            // if (!storeUndoAnnotation(annotation, undoAnnotations)) return;
            // const modifiedAnnotation = escapeEmptyItext(annotation);
            // 按照傳入的 annotation 創造 undoAnnotation
            const undoCount = undoAnnotations.undoCount <= 5 ? undoAnnotations.undoCount + 1 : 5;
            undoAnnotations.undoCount = undoCount;
            const maxCount = undoAnnotations.maxCount <= 5 ? undoAnnotations.maxCount + 1 : 5;
            undoAnnotations.maxCount = maxCount;
            Object.keys(annotation).forEach(page => {
                if (undoCount !== maxCount) { // 復原過再度畫記
                    undoAnnotations[page] = undoAnnotations[page].slice(0, undoCount);
                    undoAnnotations.maxCount = undoCount;
                }
                if (undoCount === 6) { // 可復原步驟超過 5 步
                    undoAnnotations[page].shift();
                    undoAnnotations.undoCount = 5;
                    undoAnnotations.maxCount = 5;
                }
                undoAnnotations[page].push(annotation[page]);
            });
        } else {
            undoAnnotations.undoCount = undoInfo.undoCount;
        }

        const dbAnnotation = await readAnnotationById({ id: annotationId });
        const dbAnnotations = dbAnnotation.annotations.reduce((acc, v) => ({...acc, [v.pageIndex]: v.annotation}),{})
        const annotations = { ...dbAnnotations, ...annotation };

        const result = await updateAnnotation(annotationId, {
            annotations: Object.keys(annotations).map(p => ({
                pageIndex: parseInt(p),
                annotation: annotations[p]
            })),
            isDirty: true,
            updatedAt: Date.now(),
            isDoublePageMode
        });

        const newAnnotation = Object.keys(annotation).map(p => ({
            pageIndex: parseInt(p),
            annotation: annotation[p]
        }));
        // console.log('canvas syncSingleAnnotation', result)
        strategy && strategy.syncSingleAnnotation({
            annotations: newAnnotation,
            localDB: { ...result, annotations: [] },
            id: annotationId
        });

        if (result) {
            EventBus.emit({ event: CanvasEvent.CanvasSavedEvent, payload: { annotations: newAnnotation } });

        }

        return result;
    }, [isDoublePageMode, exportCanvasToJSON, undoAnnotations, canvasDispatch, canvasJSON, updateAnnotation, annotationId, strategy]);

    const saveFilterMeasureToolCanvasJSON = useCallback(async () => {
        const annotation = exportFilterMeasureToolCanvasToJSON();
        canvasDispatch({
            type: types.CANVAS_EXPORT_SVG,
            canvasJSON: annotation
        });
        const annotationResult = await readAnnotationById({ id: annotationId });
        const annotationTemp = annotationResult.annotations.reduce((acc, v) => {
            acc[v.pageIndex] = v.annotation;
            return acc;
          }, {})
        const annotations = { ...annotationTemp, ...annotation };
        const result = await updateAnnotation(annotationId, {
            annotations: Object.keys(annotations).map(p => ({
                pageIndex: parseInt(p),
                annotation: annotations[p]
            })),
            isDirty: true,
            updatedAt: Date.now(),
            isDoublePageMode
        });

        strategy && strategy.syncAnnotation(annotationId);
        if (result) {
            //EventBus.emit({ event: CanvasEvent.CanvasSavedEvent, payload: { annotation: result } });
        }
        return result;
    }, [annotationId, canvasDispatch, exportFilterMeasureToolCanvasToJSON, isDoublePageMode, strategy, updateAnnotation]);

    const saveExtendedContentCanvasJSON = useCallback(async ({ annotation, canvasSVGObjectId }) => {

        let { extendedContentAnnotation } = await readAnnotationById({ id: annotationId })
        let checkAnnotation = false;
        const { width, height } = style;
        if (!extendedContentAnnotation) {
            extendedContentAnnotation = []
        }
        extendedContentAnnotation = extendedContentAnnotation.map((obj, index) => {
            if (obj.id === canvasSVGObjectId) {
                obj = { id: obj.id, annotation, originWidth: width, originHeight: height }
                checkAnnotation = true;
            }
            return obj
        })

        if (!checkAnnotation) {
            const saveInfo = { id: canvasSVGObjectId, annotation, originWidth: width, originHeight: height }
            extendedContentAnnotation.push(saveInfo)
        }
        const extendedContentResult = await updateAnnotation(annotationId, {
            extendedContentAnnotation
        });
        strategy && strategy.syncAnnotation(annotationId);
        return null
    }, [annotationId, readAnnotationById, updateAnnotation, style, strategy]);

    return { saveCanvasJSON, saveExtendedContentCanvasJSON, saveFilterMeasureToolCanvasJSON, style };
};

export const useConvertJSONToSVG = () => {
    const reducers = useStore();
    const [{ canvas, canvasJSON, undoAnnotations }, canvasDispatch] = reducers[StoreTypes.canvas];
    const [{ bookId, style, books }] = reducers[StoreTypes.books];
    const [{ pageIndex, isDoublePageMode }] = reducers[StoreTypes.reader];
    const book = books.find(book => book.bookId === bookId);
    const { width: bookWidth, height: bookHeight, LRFlip } = book || {};
    const { exportCanvasToJSON } = useExportCanvasToJSON();

    const resetUndoAnnotation = useCallback(({isDoublePageMode, pageIndex}) => {
        // console.log('resetUndoAnnotation',pageIndex, canvasJSON)
        const newUndoAnnotations = {};
        newUndoAnnotations.undoCount = 0;
        newUndoAnnotations.maxCount = 0;
        const emptyAnnotation = "{\"version\":\"4.6.0\",\"objects\":[]}";
        const prevPage = pageIndex * 2;
        const nextPage = pageIndex * 2 + 1;
        if (isDoublePageMode) {
            newUndoAnnotations[prevPage] = [canvasJSON[prevPage] || emptyAnnotation];
            newUndoAnnotations[nextPage] = [canvasJSON[nextPage] || emptyAnnotation];
        } else {
            newUndoAnnotations[pageIndex] = [canvasJSON[pageIndex] || emptyAnnotation];
        }

        canvasDispatch({type: types.SET_UNDO_ANNOTATIONS, undoAnnotations: {...newUndoAnnotations}});
    }, [canvasDispatch, canvasJSON])

    const [{ annotationId }] = useStore(StoreTypes.annotation);
    const { readAnnotationById } = useReadAnnotations();

    const convertJSONToSVG = useCallback(async ({ annotations = null, targetPage = pageIndex * (isDoublePageMode ? 2 : 1), pageMode = isDoublePageMode, keepCanvas = false, toSVG = false, size = style } = {}) => {
        if(!annotations){
            const result = await readAnnotationById({ id: annotationId });
            if(result){
                const annotationsInfo = result.annotations.reduce((acc, v) => {
                    acc[v.pageIndex] = v.annotation;
                    return acc;
                }, {})
                annotations=annotationsInfo
            }else{
                return;
            }
        }
        const { width, height } = size;
        canvas.clear();
        let mergedCanvasJSON;
        let sourceCanvasJSON = { left: annotations[targetPage], right: pageMode && annotations[targetPage + 1] };
        if (LRFlip === BookFlipType.RIGHT_TO_LEFT) {
            sourceCanvasJSON = { left: pageMode && annotations[targetPage + 1], right: annotations[targetPage] };
        }

        const ratio = bookWidth / bookHeight;

        for (let dir in sourceCanvasJSON) {
            if (!sourceCanvasJSON[dir]) {
                continue;
            }
            if (!mergedCanvasJSON) {
                mergedCanvasJSON = JSON.parse(sourceCanvasJSON[dir]);
                mergedCanvasJSON.objects = [];
            }
            const originalCanvasJSON = JSON.parse(sourceCanvasJSON[dir]);
            let offsetX = 0;
            let offsetY = 0;
            let scale = 1.0;
            let factor = pageMode ? 2 : 1;
            if (bookWidth * factor / bookHeight < width / height) {
                scale = height / bookHeight;
            } else {
                scale = width / (bookWidth * factor);
            }
            if (pageMode) {
                if (height * ratio < width / 2) {
                    offsetX = width / 2 - height * ratio;
                } else {    // height * ratio > width / 2
                    offsetY = (height - (width / 2) / ratio) / 2;
                }
                dir === 'right' && (offsetX = width / 2);
            } else {
                if (height * ratio < width) {
                    offsetX = (width - height * ratio) / 2;
                } else {    // height * ratio > width
                    offsetY = (height - width / ratio) / 2;
                }
            }

            originalCanvasJSON.objects.forEach((obj) => {
                obj.left = obj.left * scale + offsetX;
                obj.top = obj.top * scale + offsetY;
                if (obj.type === 'path') {
                    obj.path.forEach((p) => {
                        if (p[0] === 'z') return;
                        p[1] = p[1] * scale + offsetX;
                        p[2] = p[2] * scale + offsetY;
                        if (p[0] === 'Q') {
                            p[3] = p[3] * scale + offsetX;
                            p[4] = p[4] * scale + offsetY;
                        }
                    });
                } else if (obj.type === 'line') {
                    obj.x1 = obj.x1 * scale + offsetX;
                    obj.x2 = obj.x2 * scale + offsetX;
                    obj.y1 = obj.y1 * scale + offsetY;
                    obj.y2 = obj.y2 * scale + offsetY;
                } else if (obj.type === 'circle') {
                    obj.radius *= scale;
                } else if (obj.type === 'group' && obj.extra && obj.extra.stamp) {
                    obj.scaleX = scale;
                    obj.scaleY = scale;
                } else {
                    const originalScale = (obj.extra && obj.extra.originalScale) || [1, 1];
                    obj.scaleX = originalScale[0] * scale;
                    obj.scaleY = originalScale[1] * scale;
                }
            });

            mergedCanvasJSON.objects.push.apply(mergedCanvasJSON.objects, originalCanvasJSON.objects);
        }

        if (mergedCanvasJSON) {
            await new Promise((resolve) => {
                canvas.loadFromJSON(mergedCanvasJSON, () => {
                    resolve();
                });
            });
        } else {
            return {};
        }

        let annotation = {};
        if (toSVG) {
            let scaledWidth = width, scaledHeight = height;
            if ((width / (pageMode ? 2 : 1)) / height > ratio) {
                scaledWidth = height * ratio * (pageMode ? 2 : 1);
            } else {
                scaledHeight = (width / (pageMode ? 2 : 1)) / ratio;
            }
            let svgIndex = (pageMode ? Math.floor(targetPage / 2) : targetPage);
            annotation[svgIndex] = canvas.toSVG({ width: '100%', height: '100%', viewBox: { x: (width - scaledWidth) / 2, y: (height - scaledHeight) / 2, width: scaledWidth, height: scaledHeight }, suppressPreamble: true });
        }

        if (!keepCanvas) {
            canvas.clear();
        }

        return annotation;
    }, [LRFlip, bookHeight, bookWidth, canvas, isDoublePageMode, pageIndex, style]);

    return { convertJSONToSVG, resetUndoAnnotation };
};

export const useConvertExtendedContentJSONToSVG = () => {
    const reducers = useStore();
    const [{ style }] = reducers[StoreTypes.books];

    const convertExtendedContentCanvasJSONToSVG = useCallback(async ({ annotation, toSVG = false, originSize = null, size = style } = {}) => {
        const width = originSize ? originSize.originWidth : size.width
        const height = originSize ? originSize.originHeight : size.height

        if (!annotation) {
            return;
        }

        const originalCanvasJSON = annotation;
        let offsetX = 0;
        let offsetY = 0;
        let scaleX = 1.0;
        let scaleY = 1.0;
        scaleX = (style.width) / width;
        scaleY = (style.height) / height;

        originalCanvasJSON.objects.forEach((obj) => {
            obj.left = obj.left * scaleX + offsetX;
            obj.top = obj.top * scaleY + offsetY;
            if (obj.type === 'path') {
                obj.path.forEach((p) => {
                    if (p[0] === 'z') return;
                    p[1] = p[1] * scaleX + offsetX;
                    p[2] = p[2] * scaleY + offsetY;
                    if (p[0] === 'Q') {
                        p[3] = p[3] * scaleX + offsetX;
                        p[4] = p[4] * scaleY + offsetY;
                    }
                });
            } else if (obj.type === 'line') {
                obj.x1 = obj.x1 * scaleX + offsetX;
                obj.x2 = obj.x2 * scaleX + offsetX;
                obj.y1 = obj.y1 * scaleY + offsetY;
                obj.y2 = obj.y2 * scaleY + offsetY;
            } else if (obj.type === 'circle') {
                obj.radius *= scaleX;
            } else if (obj.type === 'polygon') {
                
            } else if (obj.type === 'group' && obj.extra && obj.extra.stamp) {
                obj.scaleX = scaleX;
                obj.scaleY = scaleY;
            } else {
                const originalScale = (obj.extra && obj.extra.originalScale) || [1, 1];
                obj.scaleX = originalScale[0] * scaleX;
                obj.scaleY = originalScale[1] * scaleY;
            }
        });

        return originalCanvasJSON;
    }, [style]);

    return { convertExtendedContentCanvasJSONToSVG };
};

let triangle;
export const useCanvasEvents = ({ eventBusType = EventBusType.Default, canvasState, canvasDispatch }) => {
    const { setMeasureControls, hideMeasureControls } = useCustomizeControls();
    const { canvas, painterMode, painterToolType, painterTool, isActive, stampType, fillType } = canvasState;
    const [{ scale, offsetX, offsetY, isDoublePageMode }] = useStore(StoreTypes.reader);
    const [{ annotationId }] = useStore(StoreTypes.annotation);
    const [, sideToolDispatch] = useStore(StoreTypes.sideTool);
    const [{ modal }, globalDispatch] = useStore(StoreTypes.global);
    const [{ role }] = useStore(StoreTypes.user);
    const [{ insertTextTool }] = useStore(StoreTypes.canvas);
    const [{ style: { width, height }, bookContent }] = useStore(StoreTypes.books);
    const { colorHex, lineWidth, painterType, lineType } = painterTool[painterToolType];
    const mouseFrom = useRef({});
    const [isDrawing, setDrawing] = useState(false);
    const [, setCanvasObject] = useState(null);
    const [initPoints, SetPoints] = useState(true);
    const [canvasObjectIsDrag, setCanvasObjectIsDrag] = useState(false);
    const paintingObject = useRef({});
    const windowSize = useWindowSize();

    window.zoom = window.zoom || 1;

    canvas.off('mouse:down');
    canvas.off('mouse:up');
    canvas.off('mouse:move');
    canvas.off('selection:created');
    canvas.off('selection:updated');
    canvas.off('selection:cleared');
    canvas.off('object:scaled');
    canvas.off('object:moved');
    canvas.off('object:added');
    canvas.off('after:render');
    canvas.on('mouse:down', function (options) {
        clearInterval(canvasReanderTime)
        if (triangle) {
            canvas.remove(triangle)
            triangle = null;
        }
        mouseFrom.current = options.e === 'MouseEvent' ? transformMouse(options.e.offsetX, options.e.offsetY) : transformMouse(options.pointer.x, options.pointer.y);

        setCanvasObjectIsDrag(false)

        const canvasObject = getCanvasObject(mouseFrom.current)
        if (canvasObject && canvasObject.extra && canvasObject.extra.type === "Link") {
            EventBus.emit({ event: ReaderToolsEvent.SetFabricObjectTargetInfo, payload: { fabricObjectTargetInfo: mouseFrom.current } });
        }
        if (canvasObject) {
            setCanvasObject(canvasObject)
            return;
        }
        if (painterMode === PainterMode.Painting) {
            paintingObject.current[painterType] = createObject(painterType, mouseFrom.current);

            if (paintingObject.current[painterType]) {
                setDrawing(true);
                if (painterType === PainterType.Arrow) {
                    canvas.add(paintingObject.current[painterType]).renderAll();
                    canvas.add(triangle).renderAll();
                } else {
                    canvas.add(paintingObject.current[painterType]).renderAll();
                }
            }

        }
    });
    canvas.on('mouse:up', (options) => {
        let pos = options.e === 'MouseEvent' ? transformMouse(options.e.offsetX, options.e.offsetY) : transformMouse(options.pointer.x, options.pointer.y);
        const pointer = canvas.getPointer(pos);

        if (getCanvasObject(mouseFrom.current)) {
            if (painterMode !== PainterMode.Eraser) {
                if (!canvasObjectIsDrag) {
                    EventBus.emit({ event: ReaderEvent.ClickCanvasSVGObjectEvent, payload: { annotationId, id: getCanvasObject(mouseFrom.current).id } });
                }
                setCanvasObjectIsDrag(false)
            } else {
                removeCanvasObjectData(canvas.getActiveObjects())
                canvasDispatch({ type: types.CANVAS_ERASE_OBJECT });
                EventBus.emit({ eventBusType, event: CanvasEvent.CanvasFinishPaintingEvent });
            }
            return;
        }
        if (painterMode === PainterMode.InsertText) {
            console.log('InsertText')
            const iText = new fabric.IText('');
            iText.set({
                left: pointer.x,
                top: pointer.y,
                angle: 0,
                padding: 10,
                cornerSize: 10,
                fill: '#000000',
                fontSize: 24,
                ...insertTextTool
            });
            EventBus.emit({ eventBusType, event: CanvasEvent.CanvasTextCreatedEvent, payload: { object: iText } });

            iText.on('changed', () => {
                EventBus.emit({ eventBusType, event: CanvasEvent.CanvasTextChangedEvent });
            });

            iText.on("editing:entered", () => {
                document.onclick = e => {
                    if (e.target.tagName.toLowerCase() !== 'canvas') {
                        iText.exitEditing();
                        document.onclick = null;
                    }
                }
            })

            iText.enterEditing();

            return;
        } else if (painterMode === PainterMode.InsertImage) {
            mouseFrom.current = pointer;
            EventBus.emit({ eventBusType, event: CanvasEvent.CanvasChooseImageSourceEvent });
            return;
        } else if (painterMode === PainterMode.Stamp) {
            StampMap[stampType] && fabric.loadSVGFromURL(StampMap[stampType], (objects, options) => {
                const bookWidth = bookContent[0].width;
                const bookHeight = bookContent[0].height;
                let scale = Math.min(width / (bookWidth * (isDoublePageMode ? 2 : 1)), height / bookHeight);
                console.log(objects)
                const obj = fabric.util.groupSVGElements(objects, options);
                const stampName = objects.find(item => item.id).id || '';
                obj.set({
                    id: short_uuid(),
                    left: pointer.x,
                    top: pointer.y,
                    originX: "center",
                    originY: "center",
                    scaleX: scale,
                    scaleY: scale,
                    stampName,
                    extra: {
                        stamp: true
                    }
                });
                EventBus.emit({ eventBusType, event: CanvasEvent.CanvasStampCreatedEventEvent, payload: { object: obj } });
            });
            return;
        } else if (painterMode === PainterMode.Eraser) {
            removeCanvasObjectData(canvas.getActiveObjects())
            canvasDispatch({ type: types.CANVAS_ERASE_OBJECT });
        } else if (painterMode === PainterMode.Painting) {
            if (painterType === PainterType.Arrow) {
                canvas.remove(paintingObject.current[painterType])
                canvas.remove(triangle)
                const selectedShape = new fabric.Group([paintingObject.current[painterType], triangle], {
                    hasBorders: true,
                    hasControls: true,
                });
                canvas.add(selectedShape)
            }
        }

        if (isActive && eventBusType === EventBusType.Default) {
            EventBus.emit({ eventBusType, event: CanvasEvent.CanvasFinishPaintingEvent });
        }

        setDrawing(false);
        SetPoints(true);
        paintingObject.current[painterType] = null;

    });

    canvas.on('mouse:move', function (options) {
        let pos = options.e === 'MouseEvent' ? transformMouse(options.e.offsetX, options.e.offsetY) : transformMouse(options.pointer.x, options.pointer.y);
        setCanvasObjectIsDrag(true);
        var pointer = canvas.getPointer(pos);
        if (isDrawing && paintingObject.current[painterType]) {

            // if (painterType === PainterType.Line) {
                // paintingObject.current[painterType].set({ x2: pointer.x, y2: pointer.y });
                // canvas.requestRenderAll();
            // } else {
                if (initPoints) {
                    if (painterType !== PainterType.Star) {
                        SetPoints(false);
                        paintingObject.current[painterType].initPoints = paintingObject.current[painterType].points;
                    }
                }
                updateObject(paintingObject.current[painterType], painterType, mouseFrom.current, pointer,pos);
            // }
        }

    });

    const selectionHandler = useCallback(e => {
        const target = e.target;
        if (target.measureTools) {
            setMeasureControls({ target })
        } else {
            hideMeasureControls({ target })
        }
        if (target.extra !== undefined) {

            const { type } = target.extra;
            sideToolDispatch({
                type: types.SET_PAINTER_TOOL_SHOW,
                isPainterPanelShow: false
            });
            if (type === CanvasSVGObjectContentType.StickyNote || type === CanvasSVGObjectContentType.Whiteboard || type === CanvasSVGObjectContentType.Link) return;
        }
        if (e.selected.length > 0) {
            for (const selected of e.selected) {
                if (selected.extra !== undefined) {
                    const { type } = selected.extra;
                    if (type === CanvasSVGObjectContentType.StickyNote || type === CanvasSVGObjectContentType.Whiteboard || type === CanvasSVGObjectContentType.Link) return;
                }
            }
        }
        const isLeft = e.target.left > (windowSize.width / 2);
        const { type } = e.target;
        let eventType = CanvasEvent.CanvasObjectSelectedEvent;
        if (type.toLowerCase() === 'i-text' && role !== Roles.EDITOR) {
            eventType = CanvasEvent.CanvasTextObjectSelectedEvent;
        }
        EventBus.emit({
            eventBusType, event: eventType,
            payload: { activeCanvasObject: e.target, sideToolDirection: isLeft ? SideToolDirection.LEFT : SideToolDirection.RIGHT }
        });

    }, [eventBusType, hideMeasureControls, role, setMeasureControls, sideToolDispatch, windowSize.width]);

    useEffect(() => {
        canvas.discardActiveObject();
        canvas.requestRenderAll();
    }, [canvas, scale])

    canvas.on('selection:created', selectionHandler);
    canvas.on('selection:updated', selectionHandler);
    canvas.on('selection:cleared', function (e) {
        EventBus.emit({ eventBusType, event: CanvasEvent.CanvasSelectionClearEvent });
    });

    const objectModifiedHandler = (e) => {
        if (painterMode === PainterMode.Painting) return;
        const object = e.target;
        object.height = Math.floor(object.height * 10000) / 10000 || 1;
        object.width = Math.floor(object.width * 10000) / 10000 || 1;
        object.top = Math.floor(object.top * 10000) / 10000 || 0;
        object.left = Math.floor(object.left * 10000) / 10000 || 0;
    }
    canvas.on('object:added', objectModifiedHandler);
    canvas.on('object:scaled', objectModifiedHandler);
    canvas.on('object:moved', objectModifiedHandler);

    canvas.on('after:render', () => {
        // keep a visible frame on each invisible object
        canvas.contextContainer.strokeStyle = 'rgba(255, 0, 0, 0.15)';
        canvas.forEachObject(function (obj) {
            if (obj.opacity === 0) {
                var bound = obj.getBoundingRect();

                canvas.contextContainer.strokeRect(
                    bound.left + 0.5,
                    bound.top + 0.5,
                    bound.width,
                    bound.height
                );
            }
        })
    });

    //座標轉換
    const transformMouse = useCallback((mouseX, mouseY) => {
        if (modal.isShowing) {
            return { x: mouseX / window.zoom, y: mouseY / window.zoom };
        } else {
            return { x: (mouseX - offsetX) / scale, y: (mouseY - offsetY) / scale };
        }
    }, [modal.isShowing, offsetX, offsetY, scale])

    const getCanvasObject = (pointer) => {
        const canvasObject = canvas.getObjects().find((obj) => {
            if (obj.extra) {
                if (obj.containsPoint(pointer) && obj.extra.type) {
                    switch (obj.extra.type) {
                        case CanvasSVGObjectContentType.StickyNote:
                        case CanvasSVGObjectContentType.Link:
                        case CanvasSVGObjectContentType.Whiteboard:
                            return obj;
                        default:
                            console.error(`CanvasSVG Object ContentType ${obj.extra.type} is not supported`);
                            return null;
                    }
                }
            }
            return null;
        })
        return canvasObject;
    }

    const removeCanvasObjectData = (objects) => {
        const objectIds = objects.map((obj) => {
            return obj.id
        })
        EventBus.emit({ eventBusType, event: ReaderEvent.DeleteCanvasSVGObjectInfoEvent, payload: { objectIds } });
    }

    const starPolygonPoints = (spikeCount, outerRadius, innerRadius) => {
        let rot = Math.PI / 2 * 3;
        const cx = outerRadius;
        const cy = outerRadius;
        const sweep = Math.PI / spikeCount;
        const points = [];

        for (let i = 0; i < spikeCount; i++) {
            let x = cx + Math.cos(rot) * outerRadius;
            let y = cy + Math.sin(rot) * outerRadius;
            points.push({ x: x, y: y });
            rot += sweep;

            x = cx + Math.cos(rot) * innerRadius;
            y = cy + Math.sin(rot) * innerRadius;
            points.push({ x: x, y: y });
            rot += sweep
        }
        return (points);
    }




    const drawStar = (_radius) => {
        var angleIncrement = Math.PI / 5;
        var ninety = Math.PI * .5;
        let points = []
        for (var i = 0; i <= 10; i++) {
            var radius = (i % 2 > 0 ? _radius : _radius * .5);
            var px = Math.cos(ninety + angleIncrement * i) * radius;
            var py = Math.sin(ninety + angleIncrement * i) * radius;
            points.push({ x: px, y: py });
        }
        return points
    }

    const createObject = (painterType, pos) => {
        let canvasObject = null;
        const transparent = 'rgba(255, 255, 255, 0)';
        const pointer = canvas.getPointer(pos);
        switch (painterType) {
            case PainterType.Arrow: //箭頭
                let arrowLinePoints = [pointer.x, pointer.y, pointer.x, pointer.y];

                canvasObject = new fabric.Line(arrowLinePoints, {
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    stroke: colorHex,
                    strokeWidth: lineWidth,
                    originX: 'center',
                    originY: 'center',
                    basePointer: pointer
                });

                const p = {
                    x2: pointer.x,
                    y2: pointer.y,
                }
                let dx = pointer.x2 - pointer.x1, dy = pointer.y2 - pointer.y1;
                let angle = Math.atan2(dy, dx);
                angle *= 180 / Math.PI;
                angle += 90;
                const traiangleSize = 35
                triangle = new fabric.Triangle({
                    angle: angle,
                    fill: colorHex,
                    top: p.y2,
                    left: p.x2,
                    width: traiangleSize,
                    height: traiangleSize,
                    originX: 'center',
                    originY: 'center',
                    stroke: colorHex
                });
                break;
            case PainterType.Line: //直線
                canvasObject = new fabric.Line([pointer.x, pointer.y, pointer.x, pointer.y], {
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    stroke: colorHex,
                    strokeWidth: lineWidth,
                    originX: 'center',
                    originY: 'center',
                    basePointer: pointer
                });
                break;
            // case PainterType.DottedLine: //虛線
            //     canvasObject = new fabric.Line([pos.x, pos.y, pos.x, pos.y], {
            //         strokeDashArray: [3, 1],
            //         stroke: colorHex,
            //         strokeWidth: lineWidth
            //     });
            //     break;
            case PainterType.RightTriangle: //直角三角形
            case PainterType.IsoscelesTriangle: //等腰三角形
                const path = `M ${pointer.x} ${pointer.y}`;
                canvasObject = new fabric.Path(path, {
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    stroke: fillType === FillType.Solid ? transparent : colorHex,
                    strokeWidth: lineWidth,
                    fill: fillType === FillType.Solid ? colorHex : transparent,
                    basePointer: pointer
                });
                break;
            case PainterType.Circle: //正圆
            case PainterType.SolidCircle: //實心正圆
                canvasObject = new fabric.Circle({
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    left: pointer.x,
                    top: pointer.y,
                    stroke: fillType === FillType.Solid ? transparent : colorHex,
                    fill: fillType === FillType.Solid ? colorHex : transparent,
                    radius: 0,
                    strokeWidth: lineWidth,
                    originX: 'center',
                    originY: 'center',
                    basePointer: pointer
                });
                break;
            case PainterType.Rectangle: //方形
                canvasObject = new fabric.Rect({
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    left: pointer.x,
                    top: pointer.y,
                    width: 0,
                    height: 0,
                    stroke: fillType === FillType.Solid ? transparent : colorHex,
                    strokeWidth: lineWidth,
                    fill: fillType === FillType.Solid ? colorHex : transparent,
                    basePointer: pointer
                });
                break;
            case PainterType.Star: //星形
                const radius = Math.sqrt(Math.pow((pointer.x - origin.x), 2) + Math.pow((pointer.y - origin.y), 2));
                const scale = radius / ((width / 2) / 2)
                canvasObject = new fabric.Polygon(drawStar((width / 2)), {
                    left: pointer.x,
                    top: pointer.y,
                    strokeDashArray: lineType === LineType.Line ? [0, 0] : [5, 5],
                    stroke: fillType === FillType.Solid ? transparent : colorHex,
                    strokeWidth: lineWidth / scale,
                    fill: fillType === FillType.Solid ? colorHex : transparent,
                    originX: 'center',
                    originY: 'center',
                    basePointer: pointer
                });
                canvasObject.scale(scale);
                break;
            default:
                break;
        };
        return canvasObject;
    }

    const updateObject = (obj, painterType, origin, pos, originPos) => {
        obj.dirty = true;
        let newPath;
        const pointer = canvas.getPointer(pos);
        let bottomLength = (pointer.x - obj.basePointer.x) * 2;
        switch (painterType) {
            case PainterType.Arrow: //箭頭  
                obj.set({
                    x2: pointer.x,
                    y2: pointer.y,
                });

                let dx = obj.x2 - obj.x1, dy = obj.y2 - obj.y1;
                let angle = Math.atan2(dy, dx);
                angle *= 180 / Math.PI;
                angle += 90;

                const traiangleSize = (lineWidth - 1) * 5 + 10;
                triangle.set({ top: obj.y2, left: obj.x2, angle: angle, width: traiangleSize, height: traiangleSize });
                break;
            case PainterType.Line: //直線
            case PainterType.DottedLine: //虛線
                obj.set({ x2: pointer.x, y2: pointer.y });
                // obj.x2 = pos.x;
                // obj.y2 = pos.y;
                // obj.left = Math.min(pos.x, origin.x);
                // obj.top = Math.min(pos.y, origin.y);
                // obj.width = Math.abs(pos.x - origin.x);
                // obj.height = Math.abs(pos.y - origin.y);
                break;
            case PainterType.RightTriangle: //直角三角形
                newPath = obj.path.slice(0, 1);
                newPath.push(['L', obj.basePointer.x, pointer.y]);
                newPath.push(['L', pointer.x, pointer.y]);
                newPath.push(['z']);
                obj.path = newPath;
                obj.left = Math.min(pointer.x, obj.basePointer.x);
                obj.top = Math.min(pointer.y, obj.basePointer.y);
                obj.width = Math.abs(pointer.x - obj.basePointer.x);
                obj.height = Math.abs(pointer.y - obj.basePointer.y);
                obj.pathOffset.x = obj.left + obj.width / 2;
                obj.pathOffset.y = obj.top + obj.height / 2;
                break;
            case PainterType.IsoscelesTriangle: //等腰三角形
                newPath = obj.path.slice(0, 1);
                newPath.push(['L', pointer.x, pointer.y]);
                newPath.push(['L', (pointer.x - bottomLength), pointer.y]);
                newPath.push(['z']);
                obj.path = newPath;
                obj.left = Math.min(pointer.x, obj.basePointer.x, pointer.x - bottomLength);
                obj.top = Math.min(pointer.y, obj.basePointer.y);
                obj.width = Math.abs(pointer.x - obj.basePointer.x) * 2;
                obj.height = Math.abs(pointer.y - obj.basePointer.y);
                obj.pathOffset.x = obj.left + obj.width / 2;
                obj.pathOffset.y = obj.top + obj.height / 2;
                break;
            case PainterType.Circle: //正圆
            case PainterType.SolidCircle: //實心正圆
                obj.radius = Math.sqrt(Math.pow((origin.x - pos.x), 2) + Math.pow((origin.y - pos.y), 2));
                obj.width = obj.radius * 2;
                obj.height = obj.radius * 2;
                break;
            case PainterType.Rectangle: //方形
                obj.left = Math.min(pointer.x, obj.basePointer.x);
                obj.top = Math.min(pointer.y, obj.basePointer.y);
                obj.width = Math.abs(pointer.x - obj.basePointer.x);
                obj.height = Math.abs(pointer.y - obj.basePointer.y);
                break;
            case PainterType.Star: //星形 
                const radius = Math.sqrt(Math.pow((originPos.x - origin.x), 2) + Math.pow((originPos.y - origin.y), 2));
                const scale = radius / (obj.width / 2)
                obj.strokeWidth = lineWidth / scale
                obj.scale(scale)
                break;
            default:
                break;
        };

        obj.setCoords();
        canvas.renderAll();
    };

    const drawArrow = (fromX, fromY, toX, toY, theta = 30, arrowLen = 10) => {
        const angle = Math.atan2(fromY - toY, fromX - toX) * 180 / Math.PI,
            angle1 = (angle + theta) * Math.PI / 180,
            angle2 = (angle - theta) * Math.PI / 180,
            topX = arrowLen * Math.cos(angle1),
            topY = arrowLen * Math.sin(angle1),
            botX = arrowLen * Math.cos(angle2),
            botY = arrowLen * Math.sin(angle2);
        let arrowLeftX = toX + topX;
        let arrowLeftY = toY + topY;
        let arrowRightX = toX + botX;
        let arrowRightY = toY + botY;
        let path = [
            ['M', arrowLeftX, arrowLeftY],
            ['L', toX, toY],
            ['L', arrowRightX, arrowRightY]
        ];
        return path;
    }

    const addCanvasImage = useCallback(async ({ image }) => {
        const fabricImage = new fabric.Image(image);
        fabricImage.set({
            left: mouseFrom.current.x,
            top: mouseFrom.current.y,
            angle: 0,
            padding: 10,
            cornerSize: 10
        });
        EventBus.emit({ eventBusType, event: CanvasEvent.CanvasImageCreatedEvent, payload: { object: fabricImage } });
        // EventBus.emit({
        //     event: ReaderToolsEvent.SVGCanvasSwitchEvent, payload: { switchType: SVGCanvasSwitchType.CANVAS }
        // });

    }, [eventBusType]);

    const addCanvasMeasuringImage = useCallback(async ({ image, options, orgImg, type, sendSelectionDispatch }) => {
        const canvasHalfWidth = canvas.width;
        const canvasHalfHeight = canvas.height;
        const imgHalfWidth = orgImg.width;
        const imgHalfHeight = orgImg.height;

        let measureInfo;
        switch (type) {
            case MeasureToolType.Protractor:
                measureInfo = {
                    left: (canvasHalfWidth / 2),
                    top: canvasHalfHeight / 2 + imgHalfHeight / 2,
                    lockScalingFlip: true,
                    originX: "center",
                    originY: "bottom",
                    ...options
                }
                break;
            case MeasureToolType.IsoscelesTriangle:
                measureInfo = {
                    left: (canvasHalfWidth / 2),
                    top: canvasHalfHeight - imgHalfHeight / 2,
                    lockScalingFlip: true,
                    originX: "center",
                    originY: "bottom",
                    ...options
                }
                break;
            case MeasureToolType.ShortRuler:
            case MeasureToolType.LongRuler:
            case MeasureToolType.RightTriangle:
                measureInfo = {
                    left: (canvasHalfWidth / 2 - imgHalfWidth / 2),
                    top: canvasHalfHeight / 2 - imgHalfHeight / 2,
                    lockScalingFlip: true,
                    originX: "left",
                    originY: "top",
                    ...options
                }
                break;

            default:
                break;
        }

        image.set(measureInfo)
        image.setControlsVisibility({
            bl: true, // 左下
            br: true, // 右下
            mb: false, // 下中
            ml: false, // 中左
            mr: false, // 中右
            mt: false, // 上中
            tl: true, // 上左
            tr: true, // 上右
            mtr: true // 旋轉控制鍵
        })


        EventBus.emit({ eventBusType, event: CanvasEvent.CanvasImageCreatedEvent, payload: { object: image, activateObject: true, sendSelectionDispatch } });
        // EventBus.emit({
        //     event: ReaderToolsEvent.SVGCanvasSwitchEvent, payload: { switchType: SVGCanvasSwitchType.CANVAS }
        // });

        // EventBus.emit({
        //     event: ReaderToolsEvent.ClickSelectEvent,
        //     payload: {
        //         readerToolType: ReaderToolType.Selection
        //     }
        // });

        EventBus.emit({
            event: ReaderToolsEvent.SetReaderToolTypeEvent,
            payload: {
                readerToolType: ReaderToolType.Select
            }
        });
    }, [canvas, eventBusType]);

    return {
        addCanvasImage,
        addCanvasMeasuringImage
    }
};

export const useFlushAnnotations = () => {
    const reducers = useStore();
    const [{ canvasJSON }, canvasDispatch] = reducers[StoreTypes.canvas];
    const [{ isDoublePageMode, pageIndex }] = reducers[StoreTypes.reader];
    const { convertJSONToSVG, resetUndoAnnotation } = useConvertJSONToSVG();
    const [{ annotationId }] = useStore(StoreTypes.annotation);
    // const { readAnnotationById } = useReadAnnotations();
    const { readTldrawAnnotationById } = useReadTldrawAnnotations();

    const flushAnnotations = useCallback(async ({ annotations = null, currentPageIndex = pageIndex, pageMode = isDoublePageMode, size }) => {
        if(!annotations){
            const result = await readTldrawAnnotationById({ id: annotationId });
            if(result){
                const annotationsInfo = result.annotations.reduce((acc, v) => {
                    acc[v.pageIndex] = v.annotation;
                    return acc;
                }, {})
                annotations=annotationsInfo
            }else{
                return;
            }
        }
        canvasDispatch({ type: types.CANVAS_RESET_SVG });
        resetUndoAnnotation({
            isDoublePageMode: pageMode,
            pageIndex: currentPageIndex
        });
        // const annotationPages = Object.keys(annotations).map(v => parseInt(v));
        // let needDumpPages = pageMode ? [...new Set(annotationPages.map(v => Math.floor(v / 2)))] : annotationPages.slice();
        // needDumpPages = needDumpPages.filter(v => v !== currentPageIndex).concat(currentPageIndex).map(v => pageMode ? v * 2 : v);
        let needDumpPages = [currentPageIndex].map(v => pageMode ? v * 2 : v);
        const canvasSVG = await needDumpPages.reduce((promiseChain, targetPage) => {
            return promiseChain.then(chainResults => {
                return convertJSONToSVG({
                    annotations,
                    targetPage,
                    pageMode,
                    keepCanvas: targetPage === (currentPageIndex * (pageMode ? 2 : 1)),
                    size,
                    toSVG: true
                }).then(annotation => ({ ...chainResults, ...annotation }));
            });
        }, Promise.resolve({}));
        // console.log('flushAnnotations canvasSVG: ', canvasSVG, 'isDoublePageMode: ', pageMode, currentPageIndex)
        canvasDispatch({
            type: types.CANVAS_IMPORT_SVG,
            canvasSVG
        });
        return canvasSVG;
    }, [canvasDispatch, convertJSONToSVG, isDoublePageMode, pageIndex, resetUndoAnnotation]);

    return flushAnnotations;
};

export const useExportInteractiveObjects = () => {
    const reducers = useStore();
    const [{ token }] = reducers[StoreTypes.user];
    const [, canvasDispatch] = reducers[StoreTypes.canvas];
    const [{ bookId, style }] = reducers[StoreTypes.books];
    const [{ annotationId }] = reducers[StoreTypes.annotation];
    const { exportCanvasToJSON } = useExportCanvasToJSON();
    const { convertJSONToSVG } = useConvertJSONToSVG();
    const markAnnotationSynced = useMarkAnnotationSynced();
    const { readAnnotationById } = useReadAnnotations();

    const exportInteractiveObjects = useCallback(async (pageIndices = []) => {
        const annotation = exportCanvasToJSON();
        canvasDispatch({
            type: types.CANVAS_EXPORT_SVG,
            canvasJSON: annotation
        });

        const result = await readAnnotationById({ id: annotationId });
        const annotationsInfo = result.annotations.reduce((acc, v) => {
            acc[v.pageIndex] = v.annotation;
            return acc;
        }, {})
        let annotations = { ...annotationsInfo, ...annotation };
        if (pageIndices.length > 0) {
            const filteredAnnotations = {};
            for (let pageIndex in annotations) {
                if (pageIndices.includes(parseInt(pageIndex))) {
                    filteredAnnotations[pageIndex] = annotations[pageIndex];
                }
            }
            annotations = filteredAnnotations;
        }
        const results = await Object.keys(annotations).reduce((promiseChain, targetPage) => {
            return promiseChain.then(chainResults => {
                return convertJSONToSVG({
                    annotations,
                    targetPage,
                    pageMode: false,
                    keepCanvas: true,
                    size: style,
                    toSVG: true
                }).then(annotation => ({ ...chainResults, ...annotation }));
            });
        }, Promise.resolve({}));

        const interactiveObjects = Object.keys(annotations).map(pageIndex => {
            const json = JSON.parse(annotations[pageIndex]).objects
                .map((obj) => Object.values(ExtraFieldsForEditor).reduce((acc, field) => {
                    acc[field] = obj[field];
                    return acc;
                }, {}));
            return {
                pageIndex: parseInt(pageIndex),
                json,
                svg: results[pageIndex]
            };
        });

        const canvasSVG = await convertJSONToSVG({
            keepCanvas: true
        });
        canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });

        if (interactiveObjects) {
            const obj = {
                interactiveObjectId: annotationId,
                bookId,
                interactiveObjects
            };

            try {
                const response = await API.postJson(`${process.env.REACT_APP_API_DOMAIN}/submitInteractiveObjects`, {
                    ...obj,
                    token: { jwt: token },
                    interactiveMetaObjects: Object.keys(annotations).map(key => ({
                        pageIndex: parseInt(key),
                        annotation: annotations[key]
                    }))
                });
                if (response.status === 'success') {
                    await markAnnotationSynced({ id: annotationId, lastSyncedAt: response.content.updatedAt });
                }
            } catch (err) {
                console.log(`submitInteractiveObjects error caught`, err);
            }
        }
    }, [annotationId, bookId, canvasDispatch, convertJSONToSVG, exportCanvasToJSON, markAnnotationSynced, style, token]);

    return exportInteractiveObjects;
};

// 應該是沒在用了 20250221
export const useFlipBook = () => {
    const reducers = useStore();
    const [, canvasDispatch] = reducers[StoreTypes.canvas];
    const [{ isDoublePageMode }, readerDispatch] = reducers[StoreTypes.reader];
    const { convertJSONToSVG } = useConvertJSONToSVG();
    const { saveFilterMeasureToolCanvasJSON } = useSaveCanvasJSON();

    const doFlipBook = useCallback(async ({ convertToSVG, keepCanvas, pageIndex }) => {
        //await saveFilterMeasureToolCanvasJSON();
        if (convertToSVG) {
            const canvasSVG = await convertJSONToSVG({ toSVG: true });
            canvasDispatch({
                type: types.CANVAS_IMPORT_SVG,
                canvasSVG
            });
        }
        await convertJSONToSVG({ keepCanvas, targetPage: pageIndex * (isDoublePageMode ? 2 : 1) });
        readerDispatch({
            type: types.SET_BOOK_PAGE_INDEX,
            pageIndex
        });
    }, [canvasDispatch, convertJSONToSVG, isDoublePageMode, readerDispatch])
    return doFlipBook;
}

export const useCreateMarkRect = () => {
    const reducers = useStore();
    const [{ canvas }] = reducers[StoreTypes.canvas];
    const [{ offsetX, offsetY, scale, fullWidthInfo }] = useStore(StoreTypes.reader);
    const createRect = useCallback((pos) => {
        let canvasObject = new fabric.Rect({
            left: ((pos.x - offsetX) / (fullWidthInfo.scale)) / scale + fullWidthInfo.offset,
            top: (pos.y - offsetY) / scale,
            width: (pos.width / fullWidthInfo.scale) / scale,
            height: pos.height / scale,
            fill: 'rgba(255, 255, 0, 0.4)'
        });
        canvas.add(canvasObject).renderAll();
        EventBus.emit({ eventBusType: EventBusType.Default, event: CanvasEvent.CanvasFinishPaintingEvent });
    }, [canvas, fullWidthInfo.offset, fullWidthInfo.scale, offsetX, offsetY, scale])
    return createRect
}


export const useCreateCourseInteractiveItem = () => {
    const [{ isDoublePageMode,scale,offsetX,offsetY  }] = useStore(StoreTypes.reader);
    const [{ style: { width, height }, bookContent }] = useStore(StoreTypes.books);
    const scaleReader = scale
    const getSVGIcon = useCallback(({ contentType }) => {
        switch (contentType) {
            case CanvasSVGObjectContentType.Link:
                return 'assets/icon/link.svg';
            case CanvasSVGObjectContentType.StickyNote:
                return 'assets/icon/page-stickynote.svg';
            case CanvasSVGObjectContentType.Whiteboard:
                return 'assets/icon/whiteboard.svg';
            default:
                console.error(`ContentType ${contentType} is not supported`);
                return null;
        }
    })

    const createCourseInteractiveItem = useCallback(({ id, contentType }) => {
        const icon = getSVGIcon({ contentType })
        if (!icon) return;
        fabric.loadSVGFromURL(icon, (objects, options) => {
            const bookWidth = bookContent[0].width;
            const bookHeight = bookContent[0].height;
            let scale = Math.min(width / (bookWidth * (isDoublePageMode ? 2 : 1)), height / bookHeight);
            const obj = fabric.util.groupSVGElements(objects, options);
            let left =  width / 2
            let top = height / 2
            if (scaleReader === 2){
                if (offsetX === 0) {
                    if (offsetY === 0) { // left-top
                        left = width /4
                        top = height /4
                    } else { // left-bottom
                        left = width /4
                        top = height /4*3
                    }
                } else {
                    if (offsetY === 0) { // right-top
                        left = width /4 *3
                        top = height / 4
                    } else { // right-bottom
                        left = width /4 *3
                        top = height / 4*3
                    }
                }
            }
            const coeff = (isDoublePageMode ?1 :(scaleReader === 2 ? 3/4: 1))
            const calib = + Math.floor(Math.random() * 100)- (scaleReader === 2 ? (isDoublePageMode ? 25: 12.5) : 50)
            obj.set({
                id: `${id}`,
                left: coeff*left+calib ,
                top:coeff*top + calib,
                originX: "center",
                originY: "center",
                scaleX:scale,
                scaleY:scale,
                hasControls: false,
                extra: {
                    type: contentType
                }
            });
            EventBus.emit({ eventBusType: EventBusType.Default, event: CanvasEvent.CreatedCanvasObjectEvent, payload: { object: obj } });

            EventBus.emit({ event: ReaderToolsEvent.SetOnCreateSVGObject, payload: { onCreateSVGObject: false } })
        })
    }, [getSVGIcon, bookContent, height, isDoublePageMode, width,offsetX,offsetY,scaleReader])
    return createCourseInteractiveItem
}

export const useRemoteAllCanvasObjects = () => {
    const reducers = useStore();
    const [{ canvas }] = reducers[StoreTypes.canvas];

    const remoteAllCanvasObjects = useCallback(({ eventBusType = EventBusType.Default }) => {
        //原本使用canvas.getActiveObjects()取不到物件，改用canvas.getObjects()
        const objectIds = canvas.getObjects().map((obj) => {
            return obj.id
        })
        EventBus.emit({ eventBusType, event: ReaderEvent.DeleteCanvasSVGObjectInfoEvent, payload: { objectIds } });
    }, [canvas])
    return remoteAllCanvasObjects
}
