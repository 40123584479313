import React, { useRef, useEffect, useCallback, useState } from 'react';
import styles from './index.module.scss';
import { withRouter } from 'react-router';
import classnames from 'classnames';
import Icon from 'components/Icon';
import resizeEvent from 'element-resize-event';
import BookContainer from 'components/BookContainer';
import Expression from 'components/Expression';
import SideToolContainer from 'components/SideToolContainer';
import ObjectPainterPanel from 'components/SideToolContents/CanvasObjectPropertyViewer/ObjectPainterPanel';
import TableContentsMenu from 'components/TableContentsMenu';
import PageSearcher from 'components/PageSearcher';
import Bookmarks from 'components/Bookmarks';
import PageTools from 'components/OfflineTools/PageTools';
import Sidebar from 'components/Sidebar';
import MusicModal from 'components/common/MusicPlayer/MusicModal';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { BookFlipType } from 'constants/flipTypes';
import { SideBarType } from 'constants/ReaderTools';
import { SideToolContent, SideToolDirection } from 'constants/sideToolContents';
import { useReadAnnotations } from 'customHooks/db';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import useCopyPaste from 'customHooks/fabric/copyPaste';
import StampCollectionBox from 'components/StampCollectionBox';
import { EventBus, EventBusType } from 'events/EventBus';
import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import DrawArea from 'components/DrawArea';
import MarkTools from 'components/MarkTools';
import ContextMenu from 'components/ContextMenu';
import useContextMenu from 'customHooks/contextMenu';
import toolsEventMap from 'components/ReaderTools/toolsEventMap';
import SidePanel from 'components/SidePanel';
import TextPanel from 'components/TextPanel';
import { ReaderToolType } from 'constants/ReaderTools';
import { withTranslation } from 'react-i18next';
import Repository from 'repositories/Repository';
import ReactGA from 'react-ga4';
import { gaEventReaderToolsClick } from 'components/AppService/GAService';
import { CategoryType } from 'constants/gaEventType';
import { PainterMode } from 'constants/painterModes';
import { PainterToolType } from 'constants/painterTypes';

import Timer from 'components/Timer';
import Stopwatch from 'components/Stopwatch';
import { Roles } from 'constants/role';
import PageSearcherForStudent from 'components/BookForStudent/PageSearcherForStudent';
import PaiNoteRecordForStudent from 'components/BookForStudent/PaiNoteRecordForStudent';
import { useReadTldrawAnnotations } from 'customHooks/tldrawDB';

// ReactGA.initialize(process.env.REACT_APP_GA);
ReactGA.initialize([
  {
    trackingId: process.env.REACT_APP_GA,
  }
]);

const { ExtendedResourceRepository } = Repository;

// const bookmarks = ['課本', '段落講解', '詞語選單', '生字表', '習作', '隨堂演練', '教學資源', '數位強打'];

const contentMenuOption = [
  ReaderToolType.Drag,
  ReaderToolType.Select,
  ReaderToolType.Painting,
  ReaderToolType.Highlighter,
  ReaderToolType.DeleteAll,
  ReaderToolType.Delete
];

const ReaderView = props => {
  const bookId = props.match.params.bookId;
  const { t } = props;
  const { channelName, clientId, userId } = props;

  const [{ role }] = useStore(StoreTypes.user);
  const [{
    isPageSearcherShow,
    isLeftBarShow,
    readerToolDirection,
    isBookmarkShow,
    drawAreaInfo,
    isMarkModeShow,
    readerToolType,
    expressionType,
    isStampCollectionBoxShow,
    isPaiNoteRecordShow
  }] = useStore(StoreTypes.reader);
  const [{ 
    modal: { isShowing: isModalShow },
    stopwatchModal: { isShowing: isStopwatchModalShow },
    timerModal: { isShowing: isTimerModalShow }
  }] = useStore(StoreTypes.global);
  const [{ annotationId, annotationType }] = useStore(StoreTypes.annotation);
  const [{ style, books }, bookDispatch] = useStore(StoreTypes.books);
  const [{ sideToolContent, sideToolDirection, isTextSideToolShow, textSideToolDirection, isPainterPanelShow }] = useStore(StoreTypes.sideTool);
  const [{ activeCanvasObject }] = useStore(StoreTypes.canvas);

  const [{ copyState, pasteState }, { copy, paste }] = useCopyPaste();

  // const { readAnnotationById } = useReadAnnotations();
  const { readTldrawAnnotationById } = useReadTldrawAnnotations();
  const decider = useReaderStrategyDecider();
  const strategy = decider.getReaderStrategy();

  const containerEl = useRef();
  const resizeTimerRef = useRef();
  const contextMenuRef = useRef();

  const [isMenuVisible] = useContextMenu(contextMenuRef);

  const book = books.find(book => book.bookId === bookId);
  const { pageInfos, LRFlip } = book || {};

  const [bookmarkData, setBookmarkData] = useState({});

  useEffect(() => {
    const fetchBookmarkData = async () => {
      const bookmarks = await ExtendedResourceRepository.getBookmarks(bookId);
      if (bookmarks) {
        setBookmarkData(bookmarks);
      }
    };
    fetchBookmarkData();
  }, [bookId]);

  const toggleSideBarHandler = useCallback(
    sideBarType => e => {
      EventBus.emit({
        event: ReaderToolsEvent.ToggleSideBarEvent,
        payload: { sideBarType }
      });
    },
    []
  );

  const togglePageSearcherHandler = useCallback(
    () => e => {
      EventBus.emit({
        event: ReaderToolsEvent.TogglePageSearcherEvent
      });
    },
    []
  );

  const togglePaiNoteRecordHandler = useCallback(
    () => e => {
      EventBus.emit({
        event: ReaderToolsEvent.TogglePaiNoteRecordEvent
      });
    },
    []
  );

  const updateBookSize = useCallback(() => {
    const container = containerEl.current;
    if (!container) return;
    const payload = {
      width: container.clientWidth,
      height: container.clientHeight
    };
    bookDispatch({
      type: types.SET_BOOK_STYLES,
      payload
    });
  }, [bookDispatch]);

  useEffect(() => {
    updateBookSize();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    (async () => {
      const { width, height } = style;
      if (!annotationId || width === null || height === null) return;
      const result = await readTldrawAnnotationById({ id: annotationId });
      if (result) {
        EventBus.emit({
          event: ReaderEvent.RefreshCanvasEvent,
          payload: {
            result: result,
            size: style
          }
        });

        EventBus.emit({
            event: ReaderEvent.SetPageIndexEvent,
            payload: { pageIndex:result.pageIndex }
        });
        // annotationDispatch({
        //   type: types.UPDATE_MARK_OBJECTS,
        //   marks: result.marks
        // });
      }
    })();

  }, [annotationId, readTldrawAnnotationById, style]);

  const handleResize = useCallback(() => {
    clearTimeout(resizeTimerRef.current);
    resizeTimerRef.current = setTimeout(updateBookSize, 1000);
  }, [updateBookSize]);

  useEffect(() => {
    const container = containerEl.current;
    resizeEvent(container, handleResize);
    return () => {
      resizeEvent.unbind(container);
    };
  }, [handleResize]);

  const prevPage = useCallback(() => {
    gaEventReaderToolsClick({
      category: CategoryType.ReaderTool,
      action: ReaderToolType.ViewPrevPage,
      label: ReaderToolType.ViewPrevPage,
    });
    EventBus.emit({
      event: ReaderEvent.ClickPreviousPageEvent,
      payload: { convertToSVG: true }
    });
  }, []);

  const nextPage = useCallback(() => {
    gaEventReaderToolsClick({
      category: CategoryType.ReaderTool,
      action: ReaderToolType.ViewNextPage,
      label: ReaderToolType.ViewNextPage,
    });
    EventBus.emit({
      event: ReaderEvent.ClickNextPageEvent,
      payload: { pageInfos, convertToSVG: true }
    });
  }, [pageInfos]);

  const selectText = e => {
    var txt = '';
    if (window.getSelection) {
      txt = window.getSelection();
      if (txt.rangeCount > 0) {
        if (txt.toString().length > 0) {
          var range = txt.getRangeAt(0);
          var props = range.getBoundingClientRect();
          var markObject = { text: txt.getRangeAt(0).toString() };
          for (var prop in props) {
            markObject[prop] = props[prop];
          }
          EventBus.emit({
            event: ReaderToolsEvent.SelectMarkEvent,
            payload: { markObject }
          });
          document.execCommand('Copy');
        }
      }
    } else if (document.getSelection) {
      txt = document.getSelection();
    } else if (document.selection) {
      txt = document.selection.createRange().text;
    } else {
      return;
    }
  };

  const changePainterMode = useCallback(({ event, painterMode, painterToolType }) => {
    EventBus.emit({ event, payload: { painterMode, painterToolType } });
  }, []);

  const setReaderToolType = type => {
    EventBus.emit({
      event: ReaderToolsEvent.SetReaderToolTypeEvent,
      payload: {
        readerToolType: type
      }
    });
    //type !== ReaderToolType.Toolbox && closeToolbox();
  }

  const mouseSelectChange = useCallback(() => {
    if (readerToolType !== ReaderToolType.Drag) {
      EventBus.emit({
        event: ReaderToolsEvent.ClickDragEvent
      });
      setReaderToolType(ReaderToolType.Drag);
      //setPanelState(PanelState.None)
    } else if (readerToolType === ReaderToolType.Drag) {
      changePainterMode({
        event: ReaderToolsEvent.ClickPainterEvent,
        painterMode: PainterMode.Painting,
        painterToolType: PainterToolType.Pen
      });
      setReaderToolType(ReaderToolType.Painting);
      //setPanelState(PanelState.None)
    }

  }, [readerToolType, changePainterMode])

  const onCompleteHandler = useCallback(() => {
    EventBus.emit({
      event: ReaderToolsEvent.PlayExpression,
      payload: {
        expressionType: ""
      }
    });

    EventBus.emit({ event: ReaderToolsEvent.SendExpressionEvent, payload: { expressionType: "" } });
  }, [])

  return (
    <div
      id="readerView"
      className={classnames(
        styles.readerView,
        styles[props.className],
        styles[`cursor${readerToolType}`]
      )}
      onMouseUp={e => isMarkModeShow && selectText(e)}
    >
      {drawAreaInfo.isDrawArea && <DrawArea drawAreaInfo={drawAreaInfo} />}
      {
        expressionType && <Expression
          expressionType={expressionType}
          loop={false}
          onComplete={onCompleteHandler} />
      }
      <Sidebar
        horizontal
        isShow={isPageSearcherShow}
        floatDirection="bottom"
        isWithMask={true}
        onMaskClick={togglePageSearcherHandler()}
      > 
        {isPageSearcherShow && role === Roles.INTERACTIVITY_STUDENT && <PageSearcherForStudent />}
        {isPageSearcherShow && role !== Roles.INTERACTIVITY_STUDENT && <PageSearcher />}
      </Sidebar>
      {isPaiNoteRecordShow && (
        <Sidebar
          horizontal
          isShow={isPaiNoteRecordShow}
          floatDirection="bottom"
          isWithMask={true}
          onMaskClick={togglePaiNoteRecordHandler()}
        >
          {isPaiNoteRecordShow && <PaiNoteRecordForStudent />}
        </Sidebar>
      )}
      <Sidebar
        className={styles.tableContentsMenu}
        vertical
        isShow={isLeftBarShow}
        floatDirection="left"
        isWithMask={true}
        onMaskClick={toggleSideBarHandler(SideBarType.LEFT)}
      >
        {isLeftBarShow && <TableContentsMenu />}
      </Sidebar>
      <Sidebar
        className={styles.stampCollectionBox}
        vertical
        floatDirection="right"
        isShow={isStampCollectionBoxShow}
      >
        {isStampCollectionBoxShow && <StampCollectionBox />}
      </Sidebar>
      <div className={styles.bookContainer}>
        {strategy && strategy.isReaderSwitchPageAvailable() && (
          <div>
            <span
              className={classnames(styles.ctrlButton, styles.left, styles.top)}
              onClick={
                LRFlip === BookFlipType.LEFT_TO_RIGHT ? prevPage : nextPage
              }
            >
              <Icon type="text" name="caretLeft" />
            </span>
            <span
              className={classnames(styles.ctrlButton, styles.right, styles.bottom)}
              onClick={
                LRFlip === BookFlipType.LEFT_TO_RIGHT ? prevPage : nextPage
              }
            >
              <Icon type="text" name="caretLeft" />
            </span>
            <span
              className={classnames(styles.ctrlButton, styles.left, styles.bottom)}
              onClick={
                LRFlip === BookFlipType.LEFT_TO_RIGHT ? nextPage : prevPage
              }
            >
              <Icon type="text" name="caretRight" />
            </span>
            <span
              className={classnames(styles.ctrlButton, styles.right, styles.top)}
              onClick={
                LRFlip === BookFlipType.LEFT_TO_RIGHT ? nextPage : prevPage
              }
            >
              <Icon type="text" name="caretRight" />
            </span>
          </div>
        )}
        <div className={styles.bookMain} ref={containerEl}>
          {annotationType && <BookContainer book={book} />}
          <MusicModal />
          {isTimerModalShow && <Timer />}
          {isStopwatchModalShow && <Stopwatch />}
        </div>
      </div>
      {role !== Roles.INTERACTIVITY_STUDENT && <Bookmarks show={isBookmarkShow} bookmarkData={bookmarkData} />}
      <PageTools />
      {isMarkModeShow && <MarkTools />}
      {
        // <Sidebar vertical isShow={isLiveStreamMasterPanelShow} isFloat={false}>
        //   {
        //     isLiveStreamMasterPanelShow && <ChatMasterPanel />
        //   }
        // </Sidebar>
      }

      <Sidebar
        vertical
        isShow={sideToolContent !== SideToolContent.None}
        floatDirection={sideToolDirection}
      >
        {SideToolDirection[sideToolDirection]}
        <SideToolContainer />
      </Sidebar>
      {
        isPainterPanelShow && <ObjectPainterPanel />
      }
      {
        (isTextSideToolShow && !isModalShow) && (
          <SidePanel direction={textSideToolDirection}>
            <TextPanel eventBusType={EventBusType.Default} activeCanvasObject={activeCanvasObject} />
          </SidePanel>
        )
      }

      <ContextMenu.Box ref={contextMenuRef} isVisible={isMenuVisible}>
        {contentMenuOption.map((item, i) => (
          <ContextMenu.Item
            key={i}
            onClick={() => {
              toolsEventMap[item].clickHandler();
            }}
          >
            {t(toolsEventMap[item].title)}
          </ContextMenu.Item>
        ))}
        {copyState && (
          <ContextMenu.Item onClick={() => copy()}>複製</ContextMenu.Item>
        )}
        {pasteState && (
          <ContextMenu.Item onClick={() => paste()}>貼上</ContextMenu.Item>
        )}
      </ContextMenu.Box>
      <div className={classnames(styles.mousePenChangeContent, styles[readerToolDirection])} >
        <div className={classnames(styles.mousePenChangeBar, styles[readerToolDirection])} onClick={() => mouseSelectChange()}></div>
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(ReaderView));
