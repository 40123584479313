import React, { useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import { EventBus } from 'events/EventBus';
import { UserEvent } from 'events/EventTypes';
import { AnnotationType } from 'constants/annotationTypes';
import { Roles } from 'constants/role';
import { usePullAnnotations, useRefreshReader } from 'customHooks/reader';
import { usePreparationSnapshot } from 'customHooks/preparation';

import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import { useReadAnnotations } from 'customHooks/db';
import { usePreparation } from 'customHooks/preparation';
import { useUpdateDisplayLanguage, useUpdateUserSettings } from 'customHooks/userSetting';
import { UserSettingsAPI } from 'api/UserSettingsAPI';
import { useFirebase } from 'customHooks/firebase';
import * as types from 'constants/actionTypes';

export const useAnnotationChecker = (props) => {
    const router = useStore(StoreTypes.router);
    const { bookId, paramsRole, paramsActivityId, paramsTeacherId, paramsInteractiveObjectId, roomId, studentId, timeSpanId, code, userName, id, remote, remoteId } = props
    const [{ annotationId, annotationType }] = useStore(StoreTypes.annotation);
    const [{ isLiveStreamMasterPanelShow, areaZoomInteractiveObjects, submenuInfo, isDoublePageMode, pageIndex, readerToolDirection, indicatorInfo: { isActive: isIndicatorActive, message: indicatorMessage } }, readerDispatch] = useStore(StoreTypes.reader);

    const [{ books }] = useStore(StoreTypes.books);
    const [{ role, token, userId, name }, userDispatch] = useStore(StoreTypes.user);
    const [, courseDispatch] = useStore(StoreTypes.course);

    const { preparationSnapshot } = usePreparationSnapshot();
    const { getPreparationList } = usePreparation();
    const { refreshReader } = useRefreshReader();
    const { updateDisplayLanguage } = useUpdateDisplayLanguage();
    const updateUserSettings = useUpdateUserSettings();
    const { readAnnotations, readAnnotationById } = useReadAnnotations();
    const { firebaseInitialize } = useFirebase();
    let payload;

    useEffect(() => {
        const book = books.find(book => book.bookId === bookId);
        if (!book) return;
        if (!annotationId && !paramsActivityId && !paramsInteractiveObjectId) {
            EventBus.emit({
                event: UserEvent.GetUserIdentityEvent,
                payload: {
                    roomId,
                    studentId,
                    timeSpanId,
                    code,
                    userName,
                    id,
                    remote,
                    bookId,
                    role: paramsRole,
                    callback: (async ({ role, userId, userName, courseInfo }) => {
                        let type = AnnotationType.GUEST;
                        switch (role) {
                            case Roles.GUEST:
                                type = AnnotationType.GUEST
                                break;
                            case Roles.TEACHER_REMOTE:
                            case Roles.TEACHER:
                                if (window.android || window.ios || window.electron) {
                                    type = AnnotationType.GUEST
                                } else {
                                    type = AnnotationType.CLASS_PREPARATION
                                }
                                break;
                            case Roles.PARENT:
                            case Roles.STUDENT:
                                type = AnnotationType.CLASS_PREPARATION
                                break;
                            case Roles.EDITOR:
                                type = AnnotationType.INTERACTIVE_OBJECT
                                break;
                            case Roles.TUTOR:
                                type = AnnotationType.ONE_TUTOR
                                break;
                            case Roles.TUTOR_USER:
                                type = AnnotationType.ONE_TUTOR
                                break;
                            case Roles.ONECLASS_TEACHER:
                                type = AnnotationType.ONE_CLASS
                                break;
                            case Roles.ONECLASS_STUDENT:
                                type = AnnotationType.ONE_CLASS
                                break;
                            default:
                                break;
                        }

                        //因為online.mietc.tw 南部工程師用內遷iframe onebook所以要判斷如果是被該網頁內簽的狀態不要擋著使用者使用
                        var isInIframe = (window.parent !== window),
                            parentUrl = '';

                        if (isInIframe) {
                            parentUrl = document.referrer;
                        }

                        const isNanipad = document.referrer.includes('pad.nani.com.tw')
                        const isLive = document.referrer.includes('live') && document.referrer.includes('oneclass.com.tw')
                        const isNewteacher = document.referrer.includes('newteacher') && document.referrer.includes('oneclass.com.tw')
                        const isOneclubBackstage = document.referrer.includes('oneclub.backstage') && document.referrer.includes('oneclass.com.tw')
                        const parentDomain = document.referrer ? document.referrer.split("://")[1].split("/")[0] : ''
                        let isValidDomain = false;
                        const checkDomain = await fetch(
                            `${process.env.REACT_APP_API_DOMAIN}/checkValidDomain`,
                            {
                                method: "POST",
                                body: JSON.stringify({
                                    domain: parentDomain
                                }),
                                headers: {
                                    "Content-Type": "application/json",
                                },
                            }
                        ).then((checkDomain) => checkDomain.json()).catch(err => {
                            console.log(err);
                        });

                        if (checkDomain && checkDomain.status === 'success') {
                            isValidDomain = checkDomain.data.isValidDomain;
                        }

                        //const isNanipad = window.location.search.includes('test')
                        if (!window.android && !window.ios && !window.electron && !isNanipad && !isValidDomain && !isLive && !isNewteacher && !isOneclubBackstage) {
                            if (role === Roles.GUEST && bookId && parentUrl.indexOf("online.mietc.tw") < 0 && window.location.search.indexOf('2s8-5c4-ej!$Mg5-4Dkc56-4c54') < 0) {
                                alert("需登入南一帳號、教育雲端帳號後才能使用")
                                router.history.push('/bookshelf')
                                return;
                            }
                        }


                        //撈出indexdb裡面的備課資料，取第一筆進書
                        payload = {
                            bookId,
                            annotationType: type,
                            name: "未命名",
                            marks: [],
                            annotationId: userId.includes("remote") ? roomId : null,
                            createNewAnnotation: userId.includes("remote")
                        };

                        if (token) {
                            await getPreparationList(bookId, token, type);
                        }

                        let callback = null;

                        if (type !== AnnotationType.ONE_TUTOR && type !== AnnotationType.ONE_CLASS) {
                            firebaseInitialize()
                            let results = await readAnnotations({ bookId, annotationType: type });
                            if (results.length > 0) {
                                const latestAnnotation = results.filter((annotation) => !annotation.isDeleted).sort((a, b) => {
                                    return b.updatedAt - a.updatedAt;
                                })[0];
                                readerDispatch({
                                    type: types.SWITCH_BOOK_PAGES_SHOW,
                                    isDoublePageMode: latestAnnotation.isDoublePageMode,
                                });
                                callback = ({ annotationId }) => {
                                    setTimeout(() => {
                                        if (remoteId) {
                                            roomId && courseDispatch({ type: types.SET_COURSE_INFO, courseInfo: { ...courseInfo, id: roomId, userRole: role, remoteId } })
                                            const isRemote = userId.includes("remote")
                                            isRemote && EventBus.emit({
                                                event: ReaderToolsEvent.SetSyncCameraEvent,
                                                payload: { isOpen: true }
                                            });
                                            isRemote && EventBus.emit({
                                                event: ReaderToolsEvent.SetSyncOnConnected,
                                                payload: { onConnected: true },
                                            });
                                        } else {
                                            courseDispatch({ type: types.SET_COURSE_INFO, courseInfo: { ...courseInfo, id: annotationId, userRole: role, remoteId } })
                                        }
                                    }, 1000)
                                    refreshReader(latestAnnotation)
                                };

                                payload.annotationId = userId.includes("remote") ? roomId : latestAnnotation.id;
                                payload.name = latestAnnotation.name;
                                payload.marks = latestAnnotation.marks;
                                payload.annotations = latestAnnotation.annotations;
                                if (type === AnnotationType.CLASS_PREPARATION) {
                                    //preparationSnapshot(latestAnnotation.id, token)
                                }
                            } else {
                                callback = ({ annotationId }) => {
                                    setTimeout(() => {
                                        roomId && courseDispatch({ type: types.SET_COURSE_INFO, courseInfo: { ...courseInfo, id: roomId, userRole: role, remoteId } })
                                        const isRemote = userId.includes("remote")
                                        isRemote && EventBus.emit({
                                            event: ReaderToolsEvent.SetSyncCameraEvent,
                                            payload: { isOpen: true }
                                        });
                                        isRemote && EventBus.emit({
                                            event: ReaderToolsEvent.SetSyncOnConnected,
                                            payload: { onConnected: true },
                                        });
                                    }, 1000)

                                }

                            }


                        } else {
                            const result = await readAnnotationById({ id: roomId });
                            if (result) {
                                payload.annotationId = result.id;
                                payload.name = result.name;
                                payload.marks = result.marks;
                                payload.annotations = result.annotations;
                            } else {
                                payload.annotationId = roomId;
                                payload.name = userName;
                                payload.createNewAnnotation = true;
                                payload.marks = [];
                                payload.annotations = [];
                            }

                            if (role === Roles.GUEST) {
                                alert("您不是該課程的成員")
                            } else {
                                let enterCourse = false;
                                let isFreeGroup = false;
                                if (courseInfo) {
                                    isFreeGroup = courseInfo.organizationId === undefined ? true : false;
                                    const time = new Date().getTime();
                                    const { startAt, endAt } = courseInfo
                                    if (time >= startAt && time <= endAt) {
                                        enterCourse = true;
                                    }
                                }

                                courseDispatch({ type: types.SET_COURSE_INFO, courseInfo: { ...courseInfo, id: roomId, userRole: role, remoteId } })


                                switch (role) {
                                    case Roles.TUTOR:
                                        firebaseInitialize()
                                        readerDispatch({ type: types.TOGGLE_LIVE_STREAM_MASTER_PANEL, isLiveStreamMasterPanelShow: true });
                                        break;
                                    case Roles.ONECLASS_TEACHER:
                                        if (enterCourse) {
                                            courseDispatch({ type: types.SET_CHIME_ROLE, chimeRole: role })
                                            !isFreeGroup && readerDispatch({ type: types.TOGGLE_LIVE_STREAM_MASTER_PANEL, isLiveStreamMasterPanelShow: true });
                                            firebaseInitialize()
                                        }
                                        break;
                                    case Roles.TUTOR_USER:
                                        firebaseInitialize()
                                        readerDispatch({ type: types.TOGGLE_LIVE_STREAM_VIEWER_PANEL, isLiveStreamViewerPanelShow: true });
                                        break;
                                    case Roles.ONECLASS_STUDENT:
                                        if (enterCourse) {
                                            courseDispatch({ type: types.SET_CHIME_ROLE, chimeRole: role })
                                            !isFreeGroup && readerDispatch({ type: types.TOGGLE_LIVE_STREAM_VIEWER_PANEL, isLiveStreamViewerPanelShow: true });
                                            firebaseInitialize()
                                        }
                                        break;
                                    default:
                                        break;
                                }
                            }
                        }

                        EventBus.emit({
                            event: ReaderEvent.CreateAndEnterAnnotationEvent,
                            payload: {
                                ...payload,
                                callback
                            }
                        });
                    })
                }
            });
        } else {
            if (annotationType === AnnotationType.CLASS_PREPARATION) {
                //preparationSnapshot(annotationId, token)
                refreshReader();
            } else if (annotationType === AnnotationType.GUEST) {
                refreshReader();
            }
        }

        return payload
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [books, token]);
}