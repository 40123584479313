import React, { useState, useEffect, useCallback } from "react";
// import ReactDOM from "react-dom";
import { Tldraw, createShapeId, DefaultColorThemePalette } from "tldraw";
import { useStore, StoreTypes } from "context";
import { groupPages, preserveSVGAspectRatio } from "util/book";
import { useFetchBooks } from "customHooks/book";
import { BookFlipType } from "constants/flipTypes";
import { TLDrawPainterEventType } from "constants/painterTypes";
import { InteractiveObject } from "./CustomShapes/InteractiveObject";
import { BookPage } from "./CustomShapes/BookPage";
import {
  ReaderToolType,
  ReaderZoomType
} from 'constants/ReaderTools';
import { TLDrawEvent } from 'events/EventTypes';
import { useEvent, EventBus } from 'events/EventBus';
import { DefaultPanelColors } from 'constants/colors';
import { confirmAlert } from "react-confirm-alert";
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import { srgbToP3 } from "util/rgbToP3";
import { useReadTldrawAnnotations } from "customHooks/tldrawDB";
import { useUpdateTldrawAnnotation } from "customHooks/tldrawDB";
import { useTldrawSetSize } from "customHooks/tldraw";

// function useTick(isEnabled = true) {
// 	const [_, setTick] = useState(0)
// 	const editor = useEditor()
// 	useEffect(() => {
// 		if (!isEnabled) return
// 		const update = () => setTick((tick) => tick + 1)
// 		editor.on('tick', update)
// 		return () => {
// 			editor.off('tick', update)
// 		}
// 	}, [editor, isEnabled])
// }

// let pageData = []
let isPreparedPage = false;
let color = DefaultPanelColors[1]
const customShape = [InteractiveObject, BookPage];

const TldrawContainer = ({ book }) => {
  const [{ bookContent, interactiveObjectSVG, interactiveObjectJSON }] =
    useStore(StoreTypes.books);
  const [{ scale, isDoublePageMode, isMarkModeShow, pageIndex }] = useStore(
    StoreTypes.reader
  );
  const [{ annotationId }] = useStore(StoreTypes.annotation);
  const [{ tldrawEditor }, tldrawDispatch] = useStore(StoreTypes.tldraw);
  const { fetchBook } = useFetchBooks();
  const updateTldrawAnnotation = useUpdateTldrawAnnotation();
  const { readTldrawAnnotationById } = useReadTldrawAnnotations();
  const { setTlDrawSize, getSize } = useTldrawSetSize();
  const { bookId, pageInfos, LRFlip } = book;
  const { width, height } = getSize(book);

  const [bookPages, setBookPages] = useState([]);

  useEffect(() => {
    let pages = [];
    const threshold = 3;
    if (isDoublePageMode) {
      let startPage = Math.max(0, pageIndex * 2 - threshold * 2);
      let endPage = Math.min(
        pageIndex * 2 + 1 + threshold * 2,
        pageInfos.length - 1
      );
      for (let i = startPage; i <= endPage; ++i) {
        pages.push(i);
      }
    } else {
      let startPage = Math.max(0, pageIndex - threshold);
      let endPage = Math.min(pageIndex + threshold, pageInfos.length - 1);
      for (let i = startPage; i <= endPage; ++i) {
        pages.push(i);
      }
    }
    pages = pages.filter((page) => !bookContent[page]);
    if (pages.length > 0) {
      fetchBook({ bookId, pages });
    }
  }, [pageIndex, fetchBook, bookId, pageInfos, bookContent, isDoublePageMode]);

  useEffect(() => {
    const groupBookPages = groupPages(bookContent, isDoublePageMode);
    if (!groupBookPages[pageIndex]) return;

    setBookPages(groupBookPages[pageIndex]);

  }, [bookContent, isDoublePageMode, pageIndex, tldrawEditor]);

  useEffect(() => {
    if (!tldrawEditor || bookPages.length === 0 || isPreparedPage || !annotationId ) return;
    if (LRFlip === BookFlipType.RIGHT_TO_LEFT) {
      bookPages.reverse();
    }
    isPreparedPage = false;
    tldrawEditor.run(
      () => {
        tldrawEditor.deleteShapes(tldrawEditor.getCurrentPageShapes());

        bookPages.forEach((page, index) => {
          const shapeId = createShapeId(`bookPage-${page.html}`);
          tldrawEditor.createShape({
            id: shapeId,
            type: "bookPage",
            x: index ? width / 2 : 0,
            y: 0,
            isLocked: true,
            props: {
              w: width / 2,
              h: height,
              svg: page.svg,
              bookInfo: book,
            },
            meta: {
              pageIndex,
              isDoublePageMode,
              bookFlipType: LRFlip,
            },
          });
          tldrawEditor.sendToBack([shapeId]);

          const interactiveObjectId = createShapeId(
            `interactiveObject-${page.pageIndex}`
          );
          tldrawEditor.createShape({
            id: interactiveObjectId,
            type: "interactiveObject",
            x: index ? width / 2 : 0,
            y: 0,
            isLocked: true,
            props: {
              svg: interactiveObjectSVG[page.pageIndex] || "",
              json: interactiveObjectJSON[page.pageIndex] || {},
              bookInfo: book,
              w: width / 2,
              h: height,
            },
            meta: {
              bookFlipType: LRFlip,
              pageIndex: page.pageIndex,
              isDoublePageMode,
            },
          });
        });

        readTldrawAnnotationById({ id:annotationId }).then(res => {
          const { annotations } = res;
          const data = annotations[pageIndex];
          if (data) {
            // tldrawEditor.createShapes(data);
            tldrawEditor.loadSnapshot(data);
            setTlDrawSize({width, height});
          }
        });

        isPreparedPage = true;

      },
      { history: "ignore", ignoreShapeLock: true }
    );

  }, [
    pageIndex,
    width,
    height,
    book,
    bookPages,
    tldrawEditor,
    isDoublePageMode,
    LRFlip,
    interactiveObjectSVG,
    interactiveObjectJSON,
    annotationId
  ]);

  useEffect(() => {
    isPreparedPage = false;
  }, [pageIndex])


  const handleResize = useCallback(() => {
    const { width, height } = getSize(book);
    setTlDrawSize({ width, height });
  }, [setTlDrawSize, book, getSize]);


  useEffect(() => {
    if (!tldrawEditor) return;

    tldrawEditor.setCameraOptions({
      constraints: {
        initialZoom: "fit-max",
        baseZoom: "fit-max",
        bounds: { w: width, h: height, x: 0, y: 0 },
        padding: { x: 0, y: 0 },
        origin: { x: 0, y: 0 },
        behavior: "contain",
      },
      zoomSteps: [1, 2, 4, 8],
      zoomSpeed: 1,
      panSpeed: 1,
      isLocked: false,
    });
    tldrawEditor.setCamera(tldrawEditor.getCamera(), { reset: true });
    const shapes = tldrawEditor.getCurrentPageShapes();
    shapes.forEach((shape) => {
      if (shape.type === "bookPage" || shape.type === "interactiveObject") {
        tldrawEditor.updateShape({
          id: shape.id,
          type: shape.type,
          props: { w: width / 2, h: height },
          x: shape.x ? width / 2 : 0,
          y: 0,
        });
      }
    });

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setTlDrawSize, tldrawEditor]);


  const tldrawEventHandler = useCallback((payload) => {
    if (!tldrawEditor) return;
    switch (payload.type) {
      case ReaderToolType.Painting:
        tldrawEditor.setCurrentTool('draw');
        break;
      case ReaderToolType.Highlighter:
        tldrawEditor.setCurrentTool('highlight');
        break;
      case ReaderToolType.LaserPen:
        tldrawEditor.setCurrentTool('laser');
        break;
      case ReaderToolType.Select:
        tldrawEditor.setCurrentTool('select');
        break;
      case ReaderToolType.Drag:
        tldrawEditor.setCurrentTool('hand');
        break;
      case ReaderToolType.Eraser:
        tldrawEditor.setCurrentTool('eraser');
        break;
      case ReaderToolType.DeleteAll:
        confirmAlert({
          title: '全部刪除',
          message: '是否刪除當前物件',
          buttons: [
            {
              label: '確定',
              onClick: () => {
                const allShapes = tldrawEditor.getCurrentPageShapes();
                const annotationShapesId = allShapes.filter(shape => shape.type !== 'bookPage' && shape.type !== 'interactiveObject').map(shape => shape.id);
                tldrawEditor.deleteShapes(annotationShapesId);

                // pageData[pageIndex] = null;
                updateTldrawAnnotation(annotationId, { [`annotations.${pageIndex}`]: null });
              }
            },
            {
              label: '取消'
            }
          ],
          closeOnEscape: true,
          closeOnClickOutside: true
        });
        break;
      case ReaderZoomType.OriginZoom:
        tldrawEditor.zoomToFit();
        break;
      case TLDrawPainterEventType.SetPenColor:
        color = payload.color;
        DefaultColorThemePalette.lightMode.black.solid = payload.color;
        DefaultColorThemePalette.lightMode.black.highlight.srgb = payload.color;
        DefaultColorThemePalette.lightMode.black.highlight.p3 = srgbToP3(payload.color);
        break;
      default:
        break;
    }
  }, [tldrawEditor, pageIndex])

  useEvent({ event: TLDrawEvent.TLDrawEvent }, tldrawEventHandler);

  return (
    <div
      style={{
        position: "fixed",
        width: width,
        height: height,
        inset: 0,
        margin: "0 auto",
      }}
      id="tlContainer"
    >
      <Tldraw
        hideUi
        shapeUtils={customShape}
        components={{
          DebugPanel: false,
          ZoomMenu: false,
          NavigationPanel: false,
          PageMenu: false,
        }}
        onMount={(editor) => {
          editor.setCurrentTool("draw");

          tldrawDispatch({ type: "SET_TLDRAW_EDITOR", payload: editor });

          editor.sideEffects.registerAfterChangeHandler('shape', (newShape) => {
            if (!isPreparedPage) return;

            const index = editor.getCurrentPageShapes().find(shape => shape.type === 'bookPage').meta.pageIndex;
            // updateTldrawAnnotation(annotationId, { [`annotations.${index}`]: editor.getCurrentPageShapes().filter(data => data.type !== 'bookPage' && data.type !== 'interactiveObject') });
            updateTldrawAnnotation(annotationId, { [`annotations.${index}`]: editor.getSnapshot() });

          });

          editor.sideEffects.registerAfterDeleteHandler('shape', (deletedShape, source, sth) => {
            if (!isPreparedPage) return;

            const { pageIndex } = deletedShape.meta; 
            // updateTldrawAnnotation(annotationId, { [`annotations.${pageIndex}`]: editor.getCurrentPageShapes().filter(data => data.type !== 'bookPage' && data.type !== 'interactiveObject') });
            updateTldrawAnnotation(annotationId, { [`annotations.${pageIndex}`]: editor.getSnapshot() });
          });

          editor.sideEffects.registerAfterCreateHandler('shape', (newShape) => {
            if (newShape.type === 'bookPage' || newShape.type === 'interactiveObject') return;
            const pageIndex = editor.getCurrentPageShapes().find(shape => shape.type === 'bookPage').meta.pageIndex;
            editor.updateShape({ ...newShape, meta: { ...newShape.meta, pageIndex, ...(!newShape.meta.color && { color: newShape.type === 'highlight'?srgbToP3(color): color }) } });

            setTimeout(() => {
              if (newShape.type === 'draw') {
                if (newShape.meta.color) {
                  const specificShape = document.querySelector(`[data-shape-id="${newShape.id}"]`) || document.querySelector(`[id="${newShape.id}"]`)
                  if (!specificShape) return;
                  const pathElement = specificShape.querySelector("path"); // 找到 <path> 元素
                  pathElement.setAttribute("fill", newShape.meta.color);
                }
              }
              if (newShape.type === 'highlight') {
                if (newShape.meta.color) {
                  const specificShapes = document.querySelectorAll(`[data-shape-id="${newShape.id}"]`).length ? document.querySelectorAll(`[data-shape-id="${newShape.id}"]`) : document.querySelectorAll(`[id="${newShape.id}"]`);
                  if (!specificShapes.length) return;
                  specificShapes.forEach((specificShape) => {
                    const pathElement = specificShape.querySelector("path");
                    pathElement.dataset.color = newShape.meta.color;
                    pathElement.style.setProperty('--color', newShape.meta.color);
                  });
                }
              }
            }, 0);
          });

          DefaultColorThemePalette.lightMode.black.solid = color;
          DefaultColorThemePalette.lightMode.black.highlight.srgb = color;
          DefaultColorThemePalette.lightMode.black.highlight.p3 = srgbToP3(color);
        }}
      ></Tldraw>
    </div>
  );
};

export default TldrawContainer;
