import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useStore, StoreTypes } from 'context';
import { Roles } from 'constants/role';
import { AnnotationType } from 'constants/annotationTypes';
import { useSyncClassPreparationDispatcher } from 'customHooks/syncClassPreparation';
import styles from './index.module.scss';
import classnames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import LinearProgress from '@material-ui/core/LinearProgress';
import EnhancedTable from '../../AnnotationActivityTab/list';
import { LottiePlayer } from 'components/LottieLoading/lottiePlayer';
import Icon from 'components/Icon';
import dateformat from 'dateformat';
import { makeStyles } from '@material-ui/core';
import { usePreparation } from 'customHooks/preparation';
import { useRefreshReader } from 'customHooks/reader';
import { useReadAnnotations, useCreateAnnotation } from 'customHooks/db';
import { ReaderEvent, ReaderToolsEvent } from 'events/EventTypes';
import { EventBus } from 'events/EventBus';
import { useFirebase } from 'customHooks/firebase';
import uuid from 'util/uuid';
import * as types from 'constants/actionTypes';
import { LOGIN_POPUP } from "constants/loginTypes";
import { useOnlineCheck } from 'customHooks/onlineCheck';
import { gaEventAnnotationSynchronizeClick } from 'components/AppService/GAService';
import WarningDialog from '../WarningDialog';
import { getNaniOneClassCookie } from 'components/Login/Popup/login.js';
import { useCreateTldrawAnnotation } from 'customHooks/tldrawDB';
import { useReadTldrawAnnotations } from 'customHooks/tldrawDB';


const useStyles = makeStyles({
    paper: {
        background: '#F2F4F8',
        borderRadius: '16px',
        width: '550px',
        margin: '16px',
        maxHeight: 'calc(100% - 32px)',
        '& .MuiDialogTitle-root': {
            padding: '32px 32px 24px',
            position: 'relative',
            '@media (max-height: 420px) and (orientation: landscape)': {
                padding: '24px 32px 12px'
            },
            '& .closeDialog': {
                position: 'absolute',
                width: '14px',
                right: '32px',
                top: '50%',
                transform: 'translateY(-50%)',
                cursor: 'pointer'
            }
        },
        '& .MuiDialogContent-root': {
            padding: '0px 32px',
            overflowY: 'scroll',
            '&.noData': {
                '@media(max-width:900px)': {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'stretch',
                    overflow: 'hidden'
                }
            }
        },
        '& .MuiDialogActions-root': {
            padding: '24px 32px',
            display: 'flex',
            justifyContent: 'space-between',
            '& div': {
                margin: '0'
            },
            '@media (max-height: 420px) and (orientation: landscape)': {
                padding: '8px 32px'
            }
        }
    }

});
const useProgressStyle = makeStyles({
    root: {
        backgroundColor: '#FBE4E0'
    },
    bar: {
        backgroundColor: '#EC7963'
    }
});
const useTextFieldStyle = makeStyles({
    root: {
        '& .MuiInputLabel-root.Mui-focused': {
            color: '#EC7963'
        },
        '& .MuiOutlinedInput-root.Mui-focused': {
            '& .MuiOutlinedInput-notchedOutline': {
                border: '2px solid #EC7963'
            }
        },
        '& .MuiOutlinedInput-input': {
            height: '15px',
        }
    }
});

const AddNewAnnotation = (props) => {
    const { label, defaultValue, onKeyPressHandler, onConfirmHandler, onCancelHandler, disableEdit } = props;
    const textFieldClasses = useTextFieldStyle();
    const textField = useRef(null);
    return (
        <div className={styles.addNewAnnotation}>
            <Icon name="AnnotationClass" />
            <div className={styles.inputArea}>
                <div>
                    <TextField
                        classes={{ root: textFieldClasses.root }}
                        label={label}
                        defaultValue={defaultValue}
                        size='small'
                        autoFocus={true}
                        style={{ width: '100%' }}
                        variant='outlined'
                        onKeyPress={onKeyPressHandler}
                        ref={textField}
                    />
                    <div className={styles.inputBtns}>
                        <Button variant='outlined'
                            onClick={() => {
                                onConfirmHandler(textField.current.querySelector('input').value.trim());
                            }}
                            disabled={disableEdit}
                        >確認</Button>
                        <Button variant='outlined' onClick={onCancelHandler}>取消</Button>
                    </div>
                </div>
                <span>{dateformat(Date.now(), 'yyyy-mm-dd HH:MM:ss')}</span>
            </div>
        </div>
    )
}

const AnnotationModal = ({ open, setOpen, selectBookID, goBook, setState, setShowMobileModal }) => {

    const progressClasses = useProgressStyle();
    const [loadingOpen, setLoadingOpen] = useState(false);
    // const { readAnnotationById } = useReadAnnotations();
    const { readTldrawAnnotationById } = useReadTldrawAnnotations();
    const { updatePreparationAnnotation, getPreparationList, syncPreparationForOffLine } = usePreparation();
    const { refreshReader } = useRefreshReader();
    const [{ role, token, isLogin }] = useStore(StoreTypes.user);
    const [{ bookId }] = useStore(StoreTypes.books);
    const [, loginDispatch] = useStore(StoreTypes.login);
    const [{ annotationId, name }, annotationDispatch] = useStore(StoreTypes.annotation);
    const [annotations, setAnnotations] = useState({});
    const [renew, setRenew] = useState(true);
    const [renameIndex, setRenameIndex] = useState(null);
    const [selectedAnnotation, setSelectedAnnotation] = useState('');
    const [openWarning, setOpenWaring] = useState({ open: false, type: '' });
    const annotationTypes = (!window.android && !window.ios && !window.electron && role === Roles.TEACHER) ? AnnotationType.CLASS_PREPARATION : AnnotationType.GUEST;

    // 讀取/更新班級記錄筆數
    useEffect(() => {
        (async () => {
            if (!renew || !bookId) return;
            setLoadingOpen(true);
            // annotationDispatch({type: types.RESET_ANNOTATION_INFO});
            const results = await getPreparationList(bookId, token, annotationTypes);
            results.sort((a, b) => {
                return b.updatedAt - a.updatedAt;
            })
            let annotations = results.reduce((acc, v) => {
                if (!v.isDeleted) {
                    if (Array.isArray(acc[v.type])) {
                        acc[v.type].push(v);
                    } else {
                        acc[v.type] = [v];
                    }
                }
                return acc;
            }, {});
            setLoadingOpen(false);
            setAnnotations(annotations);
            if (annotations[annotationTypes] && annotations[annotationTypes].length > 0) {
                annotationDispatch({ type: types.UPDATE_ANNOTATION_INFO, annotationId: annotations[annotationTypes][0].id });
                setSelectedAnnotation(annotations[annotationTypes][0].id);
            } else {
                annotationDispatch({ type: types.RESET_ANNOTATION_INFO });
            }
            setRenew(false);
            EventBus.emit({ event: ReaderEvent.SetProgressEvent, payload: { progress: 0 } });
        })();
    }, [annotationTypes, bookId, getPreparationList, open, role, setLoadingOpen, token, renew, annotationDispatch]);

    // 進書
    const { firebaseInitialize } = useFirebase();
    const enterBook = async () => {
        setLoadingOpen(true);
        if (window.android || window.ios) {
            // console.group(`enter book: ${annotationId}`)
            if (annotationId && annotationId.length > 0) {
                const annotations = await readTldrawAnnotationById({ id: annotationId });
                refreshReader(annotations);
                setLoadingOpen(false);
                setShowMobileModal(false);
                return
            } else {
                EventBus.emit({
                    event: ReaderEvent.CreateAndEnterAnnotationEvent,
                    payload: {
                        bookId: bookId || selectBookID,
                        annotationType: annotationTypes,
                        annotationId: uuid(),
                        name: '未命名',
                        createNewAnnotation: true,
                        callback: async (info) => {
                            // console.groupCollapsed(`callback info: ${JSON.stringify(info)}`)
                            if (!info) {
                                setState(prev => {
                                    return {
                                        ...prev,
                                        open: true
                                    }
                                });
                            } else {
                                // firebaseInitialize();
                                // EventBus.emit({ event: ReaderToolsEvent.SetCourseInfoEvent, payload: { id: info.annotationId, userRole: role } });
                                const annotations = await readTldrawAnnotationById({ id: info.annotationId });

                                refreshReader(annotations);
                                setLoadingOpen(false);
                                setShowMobileModal(false);
                            }
                        }
                    }
                });
                return
            }

        }
        // 只有網頁版在進書的時候會重新同步雲端資料
        if (!window.electron && !window.android && !window.ios) {
            await updatePreparationAnnotation({ id: annotationId, token })
            EventBus.emit({ event: ReaderEvent.SetProgressEvent, payload: { progress: 100 } })
        }
        // setProgressText("讀取完成")
        setTimeout(() => {
            setLoadingOpen(false);
            // setProgressOpen(false)
            EventBus.emit({
                event: ReaderEvent.CreateAndEnterAnnotationEvent,
                payload: {
                    bookId: bookId || selectBookID,
                    annotationType: annotationTypes,
                    annotationId: annotationId,
                    name: name.length === 0 ? '未命名' : name,
                    callback: (info) => {
                        if (!info) {
                            setState(prev => {
                                return {
                                    ...prev,
                                    open: true
                                }
                            });
                        } else {
                            firebaseInitialize();
                            EventBus.emit({ event: ReaderToolsEvent.SetCourseInfoEvent, payload: { id: info.annotationId, userRole: role } })
                            goBook(bookId || selectBookID);
                        }
                    }
                }
            });
        }, 1000);
    }

    // 新增班級記錄
    const [createNewAnnotation, setCreateNewAnnotation] = useState(false);
    const [disableEdit, setDisableEdit] = useState(false);
    // const createAnnotation = useCreateAnnotation();
    const createTldrawAnnotation = useCreateTldrawAnnotation();
    const { syncClassPreparation } = useSyncClassPreparationDispatcher();
    const openCreateNewAnnotation = () => {
        setCreateNewAnnotation(true);
        // annotationDispatch({type: types.RESET_ANNOTATION_INFO});
        setSelectedAnnotation('');
    }
    const addNewAnnotation = async (newAnnotationName) => {
        if (newAnnotationName !== '') {
            setDisableEdit(true);
            const annotationId = uuid();
            await createTldrawAnnotation({
                id: annotationId,
                bookId,
                name: newAnnotationName,
                type: annotationTypes,
                pageIndex: 0,
                annotations: {},
                isUpdate: false
            });
            if (annotationTypes === AnnotationType.CLASS_PREPARATION) {
                await syncClassPreparation(annotationId);
            }
            setDisableEdit(false);
            setRenew(true);
        }
        setCreateNewAnnotation(false);
    }

    // 刪除班級記錄
    const findDeleteAnnotation = () => {
        return annotations[annotationTypes].filter(annotation => annotation.id === annotationId)[0];
    }

    // 複製班級記錄
    const [createCopyAnnotation, setCreateCopyAnnotation] = useState(false);
    const { copyPreparationAnnotation } = usePreparation();
    const [copyTextFieldValue, setCopyTextFieldValue] = useState('');
    const [copyAnnotationId, setCopyAnnotationId] = useState('');
    const openCopyAnnotationField = () => {
        const copiedAnnotation = annotations[annotationTypes].filter(annotation => annotation.id === annotationId)[0];
        const copiedAnnotationName = copiedAnnotation.name;
        setCopyAnnotationId(copiedAnnotation.id);
        // annotationDispatch({type: types.RESET_ANNOTATION_INFO});
        setSelectedAnnotation('');
        setCopyTextFieldValue(`${copiedAnnotationName}_複製`);
        setCreateCopyAnnotation(true);
    }
    const copyAnnotation = async (copyAnnotationName) => {
        const copiedAnnotation = annotations[annotationTypes].filter(annotation => annotation.id === copyAnnotationId)[0];
        const name = copyAnnotationName;
        if (name !== '') {
            setDisableEdit(true);
            await copyPreparationAnnotation({ annotation: { ...copiedAnnotation, name }, token });
            setDisableEdit(false);
            setRenew(true);
        };
        setCreateCopyAnnotation(false);
    }

    // 重新命名
    const renameAnnotation = (name) => {
        if (name === '') return;
        EventBus.emit({ event: ReaderEvent.SaveAnnotationNameEvent, payload: { annotationId: annotationId, name: name } });
        // setRenew(true);
    }

    //檢查 token 過期與否
    const checkTokenExpired = (token) => {
        const jwt = require('jsonwebtoken');
        const expiration = jwt.decode(token).exp;
        if (expiration < (Date.now() / 1000)) {
            setOpenWaring({
                open: true,
                type: 'token'
            });
        }
        return expiration < (Date.now() / 1000)
    }

    // 紀錄同步並檢查網路連線及 token
    const checkIsOnline = useOnlineCheck();
    const [{ progress }, readerDispatch] = useStore(StoreTypes.reader);
    const handleWebSyncAnnotation = async () => {
        gaEventAnnotationSynchronizeClick({
            category: 'SyncAnnotation',
            action: 'web',
            label: 'sync from web'
        });
        const isOnline = await checkIsOnline();
        if (isOnline) {
            const token = JSON.parse(getNaniOneClassCookie('nani_oneclass_login_token')).jwt;
            const isTokenExpired = checkTokenExpired(token);
            if (isTokenExpired) return;
            setRenew(true);
        } else {
            readerDispatch({
                type: types.SET_ONLINE_DIALOG,
                isOnLineDialog: true,
            });
        }
    }

    const handleOfflineSyncAnnotation = useCallback(async () => {
        if (window.android || window.ios) {
            gaEventAnnotationSynchronizeClick({
                category: 'SyncAnnotation',
                action: 'mobile',
                label: 'sync from android/ios'
            });
        } else {
            gaEventAnnotationSynchronizeClick({
                category: 'SyncAnnotation',
                action: 'electron',
                label: 'sync from electron'
            });
        }

        const isOnline = await checkIsOnline();
        if (isOnline) {
            if (!window.android && !window.ios && !isLogin) {
                loginDispatch({ type: LOGIN_POPUP, popupState: true });
                return;
            }
            const token =  window.electron ? JSON.parse(getNaniOneClassCookie('nani_oneclass_login_token')).jwt : getNaniOneClassCookie('mobile_nani_oneclass_login_token');
            const isTokenExpired = checkTokenExpired(token);
            if (isTokenExpired) return;
            setLoadingOpen(true);
            // console.groupCollapsed(`handleOfflineSyncAnnotation: bookId-${JSON.stringify(bookId)} token-${JSON.stringify(token)} annotationTypes-${JSON.stringify(annotationTypes)}`);
            await syncPreparationForOffLine(bookId, token, annotationTypes);
            // console.groupCollapsed(`handleOfflineSyncAnnotation: results-${JSON.stringify(results)}`);
            setRenew(true);
            // results.sort((a, b) => {
            //     return b.updatedAt - a.updatedAt;
            // })
            // let annotations = results.reduce((acc, v) => {
            //     if (!v.isDeleted) {
            //         if (Array.isArray(acc[v.type])) {
            //             acc[v.type].push(v);
            //         } else {
            //             acc[v.type] = [v];
            //         }
            //     }
            //     return acc;
            // }, {});
            // setLoadingOpen(false);
            // setAnnotations(annotations);
            // if (annotations[annotationTypes] && annotations[annotationTypes].length > 0) {
            //     annotationDispatch({type: types.UPDATE_ANNOTATION_INFO, annotationId: annotations[annotationTypes][0].id});
            //     setSelectedAnnotation(annotations[annotationTypes][0].id);
            // } else {
            //     annotationDispatch({type: types.RESET_ANNOTATION_INFO});
            // }
        } else {
            readerDispatch({
                type: types.SET_ONLINE_DIALOG,
                isOnLineDialog: true,
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookId])

    // 刪除彈窗、token 過期彈窗功能
    const handleDeleteAnnotationCancel = () => setOpenWaring({ open: false, type: 'deleteAnnotation' });
    const handleDeleteAnnotationConfirm = () => {
        EventBus.emit({ event: ReaderEvent.DeleteAnnotationEvent, payload: findDeleteAnnotation() });
        setOpenWaring({ open: false, type: 'deleteAnnotation' });
        setRenew(true);
    }
    const handleTokenConfirm = () => {
        setOpenWaring({ open: false, type: 'token' })
    }
    const decideHandler = (type) => {
        switch (type) {
            case 'token':
                return {
                    handleCancel: null,
                    handleConfirm: handleTokenConfirm
                }
            case 'deleteAnnotation':
            default:
                return {
                    handleCancel: handleDeleteAnnotationCancel,
                    handleConfirm: handleDeleteAnnotationConfirm
                }
        }
    }


    return (
        <>
            <Dialog
                open={open}
                onClose={(e, reason) => {
                    if (reason === 'escapeKeyDown') return;
                    setOpen && setOpen(false);
                }}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
                classes={useStyles()}
                className={classnames(styles.annotationModal, { [styles.hide]: openWarning.open })}
            >
                <DialogTitle>
                    班級紀錄
                    {!window.android && !window.ios &&
                        <div className='closeDialog' onClick={() => {
                            setOpen(false);
                        }}>
                            <Icon name='CloseNotification' />
                        </div>}
                </DialogTitle>
                <DialogContent className={(!annotations[annotationTypes] || annotations[annotationTypes].length <= 0) ? 'noData' : null}>
                    {createNewAnnotation &&
                        <AddNewAnnotation
                            label='班級紀錄名稱'
                            defaultValue='未命名'
                            onConfirmHandler={addNewAnnotation}
                            onCancelHandler={() => setCreateNewAnnotation(false)}
                            onKeyPressHandler={(e) => {
                                if (disableEdit) return;
                                if (e.key === 'Escape') { setCreateNewAnnotation(false) };
                                if (e.key === 'Enter') { addNewAnnotation(e.target.value.trim()) };
                            }}
                            disableEdit={disableEdit}
                        />
                    }
                    {createCopyAnnotation &&
                        <AddNewAnnotation
                            label='班級紀錄名稱'
                            defaultValue={copyTextFieldValue}
                            onConfirmHandler={copyAnnotation}
                            onCancelHandler={() => setCreateCopyAnnotation(false)}
                            onKeyPressHandler={(e) => {
                                if (disableEdit) return;
                                if (e.key === 'Escape') { setCreateCopyAnnotation(false) };
                                if (e.key === 'Enter') { copyAnnotation(e.target.value.trim()) };
                            }}
                            disableEdit={disableEdit}
                        />
                    }
                    {loadingOpen && (
                        <>
                            <LinearProgress variant='determinate' value={progress || 0} classes={{ root: progressClasses.root, bar: progressClasses.bar }} />
                            <div style={{ position: 'absolute', left: '50%', bottom: '35%', transform: 'translateX(-50%)', textAlign: 'center', fontSize: '0.9rem' }}>資料讀取中...</div>
                            <LottiePlayer width='50%' />
                        </>
                    )}
                    
                    {
                        !loadingOpen && !createNewAnnotation && !createCopyAnnotation &&
                        (!annotations[annotationTypes] || annotations[annotationTypes].length <= 0) &&
                        <div style={{ textAlign: 'center', height: '300px', lineHeight: '280px' }}>無班級紀錄</div>
                    }
                    {!loadingOpen && <EnhancedTable
                        listData={annotations[annotationTypes] || []}
                        setOpenWaring={setOpenWaring}
                        openCopyAnnotationField={openCopyAnnotationField}
                        renameAnnotation={renameAnnotation}
                        setShowMobileModal={setShowMobileModal}
                        refreshReader={refreshReader}
                        readAnnotationById={readTldrawAnnotationById}
                        createCopyAnnotation={createCopyAnnotation}
                        createNewAnnotation={createNewAnnotation}
                        renameIndex={renameIndex}
                        setRenameIndex={setRenameIndex}
                        selectedAnnotation={selectedAnnotation}
                        setSelectedAnnotation={setSelectedAnnotation}
                    />}
                </DialogContent>
                <DialogActions>
                    <span style={{ color: '#A1A4B1', fontWeight: '500', fontSize: '16px' }}>目前有 {annotations[annotationTypes] ? annotations[annotationTypes].length : 0} 筆</span>
                    <div>
                        <Button size='large' variant='outlined'
                            style={{ width: '5.75rem', padding: '8px 11px', whiteSpace: 'nowrap' }}
                            onClick={(window.electron || window.android || window.ios) ? handleOfflineSyncAnnotation : handleWebSyncAnnotation}
                            disabled={loadingOpen || createCopyAnnotation || createNewAnnotation || renameIndex !== null}>
                            紀錄同步
                        </Button>
                        <Button size='large' variant='outlined' style={{ width: '8.75rem', marginLeft: '12px', whiteSpace: 'nowrap' }}
                            onClick={openCreateNewAnnotation}
                            disabled={loadingOpen || createCopyAnnotation || createNewAnnotation || renameIndex !== null}>
                            ＋ 新增班級紀錄</Button>
                        <Button size='large' variant='outlined'
                            style={
                                (loadingOpen || createNewAnnotation || createCopyAnnotation || renameIndex !== null) ?
                                    { background: 'none', color: 'rgba(0, 0, 0, 0.26)', border: '1px solid rgba(0, 0, 0, 0.12)', width: '3.75rem', marginLeft: '12px', whiteSpace: 'nowrap' } :
                                    { background: '#121232', color: 'white', width: '3.75rem', marginLeft: '12px', whiteSpace: 'nowrap' }
                            }
                            onClick={enterBook}
                            disabled={loadingOpen || createNewAnnotation || createCopyAnnotation || renameIndex !== null}>
                            確認
                        </Button>
                    </div>
                </DialogActions>
            </Dialog>
            <WarningDialog
                open={openWarning.open}
                type={openWarning.type}
                {...decideHandler(openWarning.type)}
            />
        </>
    );
};
export default AnnotationModal;
