import { StoreTypes } from "context";
import { useStore } from "context";
import { useCallback } from "react";

//原本 annotation 儲存的內容
// { id, bookId, type, annotations = [], isDirty = false, pageIndex = 0, name = '', lastSyncedAt = null, updatedAt = Date.now(), marks = [], isDeleted = false, isDoublePageMode = true, canvasSVGObjects = [], isUpdate = true, extendedContentAnnotation = [] }

export const useReadTldrawAnnotations = () => {
  const [{ tldrawDB }] = useStore(StoreTypes.db);

  const readTldrawAnnotationById = useCallback(
    async ({ id }) => {
      return tldrawDB.annotation.get({ id });
    },
    [tldrawDB.annotation]
  );

  const readTldrawAnnotations = useCallback(
    async ({ bookId, annotationType }) => {
      const condition = {};
      if (bookId) {
        condition.bookId = bookId;
      }
      if (annotationType) {
        condition.type = annotationType;
      }
      return tldrawDB.annotation.where(condition).toArray();
    },
    [tldrawDB.annotation]
  );

  return {
    readTldrawAnnotationById,
    readTldrawAnnotations,
  };
};


export const useCreateTldrawAnnotation = () => {
  const [{ tldrawDB }] = useStore(StoreTypes.db);
  const { readTldrawAnnotationById } = useReadTldrawAnnotations();

  const createTldrawAnnotation = useCallback(async ({ id, bookId, type, annotations = {}, pageIndex = 0, name = '', lastSyncedAt = null, updatedAt = Date.now(), isDeleted = false, isDoublePageMode = true, isUpdate = true, extendedContentAnnotation = [] }) => {
    
    await tldrawDB.annotation.put({ id, bookId, type, annotations, pageIndex, name, updatedAt, lastSyncedAt, isDeleted, isDoublePageMode, isUpdate, extendedContentAnnotation });

    return await readTldrawAnnotationById({ id });

}, [tldrawDB.annotation, readTldrawAnnotationById]);

  return createTldrawAnnotation;
};

export const useUpdateTldrawAnnotation = () => {
  const [{ tldrawDB }] = useStore(StoreTypes.db);
  const { readTldrawAnnotationById } = useReadTldrawAnnotations();

  const updateTldrawAnnotation = useCallback(async (id, payload) => {
      await tldrawDB.annotation.update(id, payload);
      return readTldrawAnnotationById({ id });
  }, [tldrawDB.annotation, readTldrawAnnotationById]);

  return updateTldrawAnnotation;
};

export const useDeleteAnnotation = () => {
  const [{ tldrawDB }] = useStore(StoreTypes.db);
  const { readTldrawAnnotationById } = useReadTldrawAnnotations();

  const deleteTldrawAnnotation = useCallback(async (id) => {
      await tldrawDB.annotation.delete(id);
      return readTldrawAnnotationById({ id });
  }, [tldrawDB.annotation, readTldrawAnnotationById]);

  const deleteTldrawAllAnnotation = useCallback(async () => {
    tldrawDB.annotation.clear();
      return null;
  }, [tldrawDB.annotation]);

  return { deleteTldrawAnnotation, deleteTldrawAllAnnotation };
};
