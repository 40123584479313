import { StoreTypes } from "context";
import { useStore } from "context";
import { useCallback } from "react";
import { toFixed } from "tldraw";

export const useTldrawSetSize = () => {
  const [{ tldrawEditor }] = useStore(StoreTypes.tldraw);
  const [
    {
      fullWidthInfo: { mode: fullWidthMode },
    },
  ] = useStore(StoreTypes.reader);

  const getSize = useCallback(
    (book) => {
      const readerView = document.querySelector("#readerView");
      let width = 500;
      let height = 500;

      if (fullWidthMode) {
        width = readerView?.clientWidth;
        height = readerView?.clientHeight;
      } else {
        // TODO: 符合書本比例時，該以長為基準，還是寬為基準，以及對應長寬的計算，需要重新思考。尤其是螢幕超大，高 3000px ，而書高（book.height）是 1000px 的情況。
        if (
          book.height > readerView?.clientHeight &&
          readerView?.clientHeight * ((book.width * 2) / book.height) <
            readerView?.clientWidth
        ) {
          width = readerView?.clientHeight * ((book.width * 2) / book.height);
          height = readerView?.clientHeight;
        } else {
          width = readerView?.clientWidth;
          height = readerView?.clientWidth * (book.height / (book.width * 2));
        }
      }

      return { width, height };
    },
    [fullWidthMode]
  );

  const setTlDrawSize = useCallback(
    ({ width, height }) => {
      tldrawEditor.setCameraOptions({
        constraints: {
          initialZoom: "fit-max",
          baseZoom: "fit-max",
          bounds: { w: width, h: height, x: 0, y: 0 },
          padding: { x: 0, y: 0 },
          origin: { x: 0, y: 0 },
          behavior: "contain",
        },
        zoomSteps: [1, 2, 4, 8],
        zoomSpeed: 1,
        panSpeed: 1,
        isLocked: false,
      });
      tldrawEditor.setCamera(tldrawEditor.getCamera(), { reset: true });
      tldrawEditor.run(
        () => {
          const shapes = tldrawEditor.getCurrentPageShapes();
          const oldWidth =
            shapes.filter((shape) => shape.type === "bookPage")[0].props.w * 2;
          const oldHeight = shapes.filter(
            (shape) => shape.type === "bookPage"
          )[0].props.h;
          const scaleWidth = width / oldWidth;
          const scaleHeight = height / oldHeight;
          shapes.forEach((shape) => {
            if (
              shape.type === "bookPage" ||
              shape.type === "interactiveObject"
            ) {
              tldrawEditor.updateShape({
                id: shape.id,
                type: shape.type,
                props: { w: width / 2, h: height },
                x: shape.x ? width / 2 : 0,
                y: 0,
              });
            }

            if (shape.type === "draw" || shape.type === "highlight") {
              const newSegments = [];
              for (const segment of shape.props.segments) {
                newSegments.push({
                  ...segment,
                  points: segment.points.map(({ x, y, z }) => {
                    return {
                      x: toFixed(scaleWidth * x),
                      y: toFixed(scaleHeight * y),
                      z,
                    };
                  }),
                });
              }
              tldrawEditor.updateShape({
                id: shape.id,
                props: { segments: newSegments },
                x: shape.x * scaleWidth,
                y: shape.y * scaleHeight,
              });
            }
          });
        },
        { ignoreShapeLock: true }
      );
    },
    [tldrawEditor]
  );
  return { setTlDrawSize, getSize };
};
