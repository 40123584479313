import { useCallback, useEffect } from 'react';
import { useStore, StoreTypes } from 'context';
import * as types from 'constants/actionTypes';
import { SideToolContent } from 'constants/sideToolContents';
import { PainterMode } from 'constants/painterModes';
import {
  ReaderEvent,
  ReaderToolsEvent,
  PainterEvent,
  ActivityEvent,
  InteractiveObjectEvent,
  CanvasEvent,
} from 'events/EventTypes';
import {
  useUpdateFullWidthInfo,
  useUpdateReaderScaleInfo,
  useUpdateAreaZoomInfo,
  useUpdateAreaZoomForPageButton,
  useSetPageIndex
} from 'customHooks/reader';
import {
  useConvertJSONToSVG,
  useSaveCanvasJSON,
  useFlushAnnotations,
  useExportInteractiveObjects
} from 'customHooks/canvas';
import { ReaderToolType, SideBarType, ReaderZoomType, SVGCanvasSwitchType } from 'constants/ReaderTools';
import { Roles } from 'constants/role';
import { useEvent } from 'events/EventBus';
import { EventBusType, EventBus } from 'events/EventBus';
import { useInteractiveObjectContentCommandFactory } from 'customHooks/InteractiveObjectContentCommands/commandFactory';
import { useCanvasSVGObjectContentCommandFactory } from 'customHooks/CanvasSVGObjectContentCommands/commandFactory';
import { AnnotationType } from 'constants/annotationTypes';
import { InteractiveObjectContentType } from 'constants/interactiveObjectContentTypes';
import {
  useReadAnnotations,
  useUpdateAnnotation,
  useCreateAnnotation,
  useCreateReaderToolSettings,
  useReadReaderToolSettings,
  useMarkAnnotationDeleted
} from 'customHooks/db';
import { useReaderStrategyDecider } from 'customHooks/Strategies/ReaderStrategies';
import { useSyncClassPreparationDispatcher } from 'customHooks/syncClassPreparation';
import uuid, { short_uuid } from 'util/uuid';
import { isExist } from 'util/helper'
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

import { CategoryType } from 'constants/gaEventType';
import { gaEventInteractiveObjectClick } from 'components/AppService/GAService';
import { useCreateCourseInteractiveItem, useRemoteAllCanvasObjects } from 'customHooks/canvas';
//import { useCourse } from 'customHooks/course';
import { useOnlineCheck } from 'customHooks/onlineCheck';
import { usePaiNote } from 'customHooks/paiNote';
import { useCreateTldrawAnnotation } from 'customHooks/tldrawDB';
import { useReadTldrawAnnotations } from 'customHooks/tldrawDB';

let timer;
let nowPageIndex=0

export const useReaderEventHandler = () => {
  const reducers = useStore();
  const [{ canvasSVGObjectId, pageIndex, isDoublePageMode, fullScreenState, isSynchronousCamera, onCreateSVGObject, readerToolType, paiNotePage }, readerDispatch] = reducers[
    StoreTypes.reader
  ];
  const [{ undoAnnotations, canvasJSON }, canvasDispatch] = reducers[StoreTypes.canvas];
  const [, globalDispatch] = reducers[StoreTypes.global];
  const [{ books, bookId, catalog, interactiveObjectJSON, style: { width, height } }, bookDispatch] = reducers[StoreTypes.books];
  const [{ annotationId, marks }, annotationDispatch] = reducers[
    StoreTypes.annotation
  ];
  const [{ sideToolContent }, sideToolDispatch] = reducers[StoreTypes.sideTool];
  const [{ firestore }, courseDispatch] = reducers[StoreTypes.course];
  const router = reducers[StoreTypes.router];
  const [{ role }] = reducers[StoreTypes.user];
  const { convertJSONToSVG, resetUndoAnnotation } = useConvertJSONToSVG();
  const { saveCanvasJSON } = useSaveCanvasJSON();
  const flushAnnotations = useFlushAnnotations();
  const CommandFactory = useInteractiveObjectContentCommandFactory();
  const CanvasSVGCommandFactory = useCanvasSVGObjectContentCommandFactory();
  const exportInteractiveObjects = useExportInteractiveObjects();
  const book = books.find(book => book.bookId === bookId);
  const { readAnnotationById } = useReadAnnotations();
  const { readTldrawAnnotationById } = useReadTldrawAnnotations();
  const createReaderToolSettings = useCreateReaderToolSettings();
  const readReaderToolSettings = useReadReaderToolSettings();
  const updateAnnotation = useUpdateAnnotation();
  // const createAnnotation = useCreateAnnotation();
  const createTldrawAnnotation = useCreateTldrawAnnotation();
  const markAnnotationDeleted = useMarkAnnotationDeleted();
  const updateFullWidthInfo = useUpdateFullWidthInfo();
  const updateReaderScaleInfo = useUpdateReaderScaleInfo();
  const { updateAreaZoomInfo, updateAreaZoomEventInfo } = useUpdateAreaZoomInfo();
  const updateAreaZoomForPageButton = useUpdateAreaZoomForPageButton();
  const decider = useReaderStrategyDecider();
  const strategy = decider.getReaderStrategy();
  const { syncClassPreparation } = useSyncClassPreparationDispatcher();
  const { setPageIndex } = useSetPageIndex();
  const createCourseInteractiveItem = useCreateCourseInteractiveItem();
  const remoteAllCanvasObjects = useRemoteAllCanvasObjects();
  //const [, { setEventStatusToFirebase, setSyncCanvasTargetId }] = useCourse();
  const { saveStudentAnswer } = usePaiNote(pageIndex);

  const checkIsOnline = useOnlineCheck()

  const setPageIndexHandler = useCallback(
    async ({ pageIndex, convertToSVG }) => {
      setPageIndex({ pageIndex, convertToSVG });
      //firestore && setEventStatusToFirebase({ pageIndex });
    },
    [setPageIndex, firestore]
  );

  const previousPageEventHandler = useCallback(
    async ({ convertToSVG }) => {
      if (role === Roles.INTERACTIVITY_STUDENT) {
        const nowPageIndex = paiNotePage.indexOf(pageIndex);
        if (nowPageIndex === 0) {
          globalDispatch({
            type: types.SET_GROUP_INTERACTIVITY_SNACKBAR,
            payload: {
              message: '已經是第一頁了！',
              style: 'info',
            }
          })
          return;
        }
        
        await saveStudentAnswer();

        setPageIndexHandler({
          pageIndex: paiNotePage[nowPageIndex - 1],
          convertToSVG
        });
        return
      }
      setPageIndexHandler({
        pageIndex: Math.max(pageIndex - 1, 0),
        convertToSVG
      });
    },
    [pageIndex, paiNotePage, role, setPageIndexHandler]
  );

  const nextPageEventHandler = useCallback(
    async ({ pageInfos, convertToSVG }) => {
      if (role === Roles.INTERACTIVITY_STUDENT) {
        const nowPageIndex = paiNotePage.indexOf(pageIndex);
        if (nowPageIndex === paiNotePage.length - 1) {
          globalDispatch({
            type: types.SET_GROUP_INTERACTIVITY_SNACKBAR,
            payload: {
              message: '已經是最後一頁了！',
              style: 'info',
            }
          });
          return
        }

        await saveStudentAnswer();

        setPageIndexHandler({
          pageIndex: paiNotePage[nowPageIndex + 1],
          convertToSVG
        });
        return
      }
      const max = isDoublePageMode
        ? Math.floor((pageInfos.length - 1) / 2)
        : pageInfos.length - 1;
      setPageIndexHandler({
        pageIndex: Math.min(pageIndex + 1, max),
        convertToSVG
      });
    },
    [isDoublePageMode, pageIndex, paiNotePage, role, setPageIndexHandler]
  );

  const togglePageModeHandler = useCallback(
    async ({ isDoublePageMode: newPageMode, targetPageIndex }) => {
      const { pageInfos } = book;
      let newPageIndex = targetPageIndex;
      if (typeof newPageIndex === 'undefined') {
        if (newPageMode === isDoublePageMode) {
          newPageIndex = pageIndex;
        } else {
          newPageIndex = newPageMode
            ? Math.max(Math.floor(pageIndex / 2), 0)
            : Math.min(pageIndex * 2, pageInfos.length);
        }
      }
      readerDispatch({
        type: types.SET_BOOK_PAGE_INDEX,
        pageIndex: newPageIndex
      });
      if (newPageMode !== isDoublePageMode) {
        resetUndoAnnotation({
          isDoublePageMode: newPageMode,
          pageIndex: newPageIndex
        });
        readerDispatch({
          type: types.SWITCH_BOOK_PAGES_SHOW,
          isDoublePageMode: newPageMode
        });

        await updateAnnotation(annotationId, {
          pageIndex: newPageIndex,
          isDoublePageMode: newPageMode
        });

        // await flushAnnotations({
        //   currentPageIndex: newPageIndex,
        //   pageMode: newPageMode
        // });

        //canvasDispatch({ type: types.CANVAS_INACTIVATE });

        strategy && strategy.syncAnnotation(annotationId);
      }
    },
    [book, readerDispatch, isDoublePageMode, pageIndex, resetUndoAnnotation, updateAnnotation, annotationId, flushAnnotations, strategy]
  );

  const setReaderToolHeightHandler = useCallback(
    ({ readerToolHeight }) => {
      readerDispatch({
        type: types.SET_READER_TOOL_HEIGHT,
        readerToolHeight
      });
    },
    [readerDispatch]
  );

  const setReaderToolsHandler = useCallback(({ readerTools }) => {
    readerDispatch({
      type: types.SET_READER_TOOL,
      readerTools
    });
  }, [readerDispatch])

  const reimportSVG = useCallback(async (annotations) => {
    const canvasSVG = await convertJSONToSVG({
      annotations: annotations,
      keepCanvas: true,
      toSVG: true
    });
    // console.log('reimportSVG canvasSVG', canvasSVG)
    canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });
    canvasDispatch({ type: types.CANVAS_INACTIVATE });
  }, [canvasDispatch, convertJSONToSVG]);

  const changeStampStatusHandler = useCallback(
    ({ painterMode, stampType }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_STAMP_STATUS,
        painterMode,
        stampType
      });
    },
    [canvasDispatch]
  );

  const toggleSideBarHandler = useCallback(
    ({ sideBarType }) => {
      readerDispatch({
        type:
          sideBarType === SideBarType.LEFT
            ? types.TOGGLE_LEFT_BAR
            : types.TOGGLE_RIGHT_BAR
      });
    },
    [readerDispatch]
  );

  const toggleFullScreenHandler = useCallback(() => {
    readerDispatch({
      type: types.SET_FULLSCREEN_STATE
    });
    const docElm = document.documentElement;
    if (fullScreenState) {
      if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      }
      else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      //FireFox 
      if (docElm.mozRequestFullScreen) {
        docElm.mozRequestFullScreen();
      }
      //Chrome
      else if (docElm.webkitRequestFullScreen) {
        docElm.webkitRequestFullScreen();
      }
      //IE11
      else if (docElm.msRequestFullscreen) {
        docElm.msRequestFullscreen();
      }
    }
  }, [fullScreenState, readerDispatch]);

  const togglePageSearcherHandler = useCallback(() => {
    readerDispatch({
      type: types.TOGGLE_PAGE_SEARCHER
    });
  }, [readerDispatch]);

  const clickReaderOptionPanelHandler = useCallback(async () => {
    sideToolDispatch({
      type: types.SET_SIDE_TOOL_CONTENT,
      sideToolContent:
        sideToolContent !== SideToolContent.ReaderOptionPanel
          ? SideToolContent.ReaderOptionPanel
          : SideToolContent.None
    });
  }, [sideToolContent, sideToolDispatch]);

  const clickStampPanelHandler = useCallback(async () => {
    canvasDispatch({ type: types.CANVAS_INACTIVATE });
    await reimportSVG();
    sideToolDispatch({
      type: types.SET_SIDE_TOOL_CONTENT,
      sideToolContent:
        sideToolContent !== SideToolContent.StampPanel
          ? SideToolContent.StampPanel
          : SideToolContent.None
    });
  }, [canvasDispatch, reimportSVG, sideToolContent, sideToolDispatch]);

  const setObjectPropertyHandler = useCallback(
    ({ objectProperty }) => {
      sideToolDispatch({
        type: types.SET_CANVAS_OBJECT_PROPERTY,
        objectProperty
      });
    },
    [sideToolDispatch]
  );

  const toggleBookmarkHandler = useCallback(() => {
    readerDispatch({
      type: types.TOGGLE_BOOKMARK
    });
  }, [readerDispatch]);

  const LoadReaderToolSettingsHandler = useCallback(async ({ role }) => {
    const response = await readReaderToolSettings(bookId);
    if (response) {
      response.painterTool &&
        canvasDispatch({
          type: types.SET_CANVAS_PAINTER_TOOL,
          painterTool: response.painterTool
        });
      response.insertTextTool &&
        canvasDispatch({
          type: types.SET_CANVAS_INSERT_TEXT_TOOL,
          insertTextTool: response.insertTextTool
        });
      response.fullWidthMode !== undefined &&
        readerDispatch({
          type: types.SET_FULL_WIDTH_INFO,
          fullWidthInfo: { mode: role === Roles.EDITOR ? false : response.fullWidthMode }
        });
    }
  }, [bookId, canvasDispatch, readReaderToolSettings, readerDispatch]);

  const clickDragHandler = useCallback(async () => {
    canvasDispatch({ type: types.CANVAS_ACTIVATE });
    await reimportSVG();
    readerDispatch({ type: types.SET_MARK_MODE, isMarkModeShow: false });
    readerDispatch({ type: types.SET_MARK_TOOLS_SHOW, isMarkToolsShow: false });
  }, [canvasDispatch, reimportSVG, readerDispatch]);

  const changePainterModeHandler = useCallback(
    ({ painterMode, painterToolType }) => {
      readerDispatch({ type: types.SET_MARK_MODE, isMarkModeShow: false });
      canvasDispatch({
        type: types.CANVAS_CHANGE_PAINTER_MODE,
        painterMode,
        painterToolType
      });

      //const result = await readAnnotationById({ id: annotationId });
      // const annotations = result.annotations.reduce((acc, v) => {
      //   acc[v.pageIndex] = v.annotation;
      //   return acc;
      // }, {})

      //
      // canvasDispatch({
      //   type: types.CANVAS_RESTORE_FROM_DB,
      //   annotations
      // });
      // await flushAnnotations({
      //   annotations,
      //   currentPageIndex: pageIndex
      // });
      // canvasDispatch({
      //   type: types.CANVAS_RESTORE_FROM_DB,
      //   annotations:{}
      // });
    },
    [canvasDispatch, readerDispatch]
  );

  const changeBrushColorHandler = useCallback(
    ({ color }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_COLOR,
        changeColorRgb: color,
        changeColorHex: color
      });
    },
    [canvasDispatch]
  );

  const changeBrushWidthHandler = useCallback(
    ({ lineWidth }) => {
      canvasDispatch({
        type: types.CANVAS_DRAWING_BRUSH_LINE_WIDTH,
        changeLineWidth: lineWidth
      });
    },
    [canvasDispatch]
  );

  const changeBrushTypeHandler = useCallback(
    ({ brushType }) => {
      canvasDispatch({
        type: types.CHANGE_DRAWING_BRUSH,
        changeDrawingBrush: brushType
      });
    },
    [canvasDispatch]
  );

  const changeShapeFillTypeHandler = useCallback(
    ({ fillType }) => {
      canvasDispatch({ type: types.CANVAS_CHANGE_SHAPE_FILL_TYPE, fillType });
    },
    [canvasDispatch]
  );

  const changelineTypeHandler = useCallback(({ lineType }) => {
    canvasDispatch({ type: types.CANVAS_CHANGE_LINE_TYPE, lineType });
  }, [canvasDispatch])


  const changePainterTypeHandler = useCallback(
    ({ painterType }) => {
      canvasDispatch({ type: types.CANVAS_CHANGE_PAINTER_TYPE, painterType });
    },
    [canvasDispatch]
  );

  const changeReaderToolStyleHandler = useCallback(
    ({ color }) => {
      readerDispatch({ type: types.CHANGE_READER_TOOL_STYLE, color });
    },
    [readerDispatch]
  );

  const changeReaderToolDirectionHandler = useCallback(
    direction => {
      readerDispatch({ type: types.CHANGE_READER_TOOL_DIRECTION, direction });
    },
    [readerDispatch]
  );

  const changeTextColorHandler = useCallback(
    ({ color }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_COLOR,
        changeColorRgb: color
      });
    },
    [canvasDispatch]
  );

  const changeTextFontSizeHandler = useCallback(
    ({ fontSize }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_FONTSIZE,
        changeFontSize: fontSize
      });
    }, [canvasDispatch])

  const changeTextFontStyleHandler = useCallback(
    ({ fontStyle }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_FONTSTYLE,
        changeFontStyle: fontStyle
      });
    },
    [canvasDispatch]
  );

  const changeTextFontWeightHandler = useCallback(
    ({ fontWeight }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_FONTWEIGHT,
        changeFontWeight: fontWeight
      });
    },
    [canvasDispatch]
  );

  const changeTextUnderlineHandler = useCallback(
    ({ underline }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_UNDERLINE,
        changeUnderline: underline
      });
    },
    [canvasDispatch]
  );

  const changeTextBgColorHandler = useCallback(
    ({ backgroundColor }) => {
      canvasDispatch({
        type: types.CANVAS_CHANGE_TEXT_BGCOLOR,
        changeBgColor: backgroundColor
      });
    },
    [canvasDispatch]
  );

  const RefreshCanvasHandler = useCallback(
    async({ result, size }) => {
      clearTimeout(timer);
      if(!result.annotations)return;
      timer = setTimeout(async () => {
        // 在指定時間區間內沒有再次點擊時執行的方法
        // const annotations = result.annotations.reduce((acc, v) => {
        //   acc[v.pageIndex] = v.annotation;
        //   return acc;
        // }, {})
        // canvasDispatch({
        //   type: types.CANVAS_RESTORE_FROM_DB,
        //   annotations
        // });
        // await flushAnnotations({
        //   annotations: annotations,
        //   currentPageIndex: result.pageIndex,
        //   size
        // });
      }, 500);

    },
    [canvasDispatch, flushAnnotations,pageIndex,annotationId]
  );

  const playExpressionEventHandler = useCallback(({ expressionType }) => {
    readerDispatch({
      type: types.SET_EXPRESSION_TYPE,
      expressionType
    });
  }, [readerDispatch])

  const clickInteractiveObjectHandler = useCallback(
    async ({
      id,
      pageIndex,
      isDoublePageMode,
      interactiveObjectState,
      setInteractiveObjectState,
      target
    }) => {
      const obj = interactiveObjectJSON[pageIndex];

      if (obj && obj[id]) {
        // 派筆記學生端欲靜止的「功能」---start
        if (role === Roles.INTERACTIVITY_STUDENT && obj[id].contentType === InteractiveObjectContentType.ToggleDisplay) return;
        // 派筆記學生端欲靜止的「功能」---end
        //if (strategy.isInteractiveObjectTriggerable(obj[id])) {
        gaEventInteractiveObjectClick({
          category: CategoryType.InteractiveObject,
          action: obj[id].contentType,
          label: obj[id].src
        })
        const command = CommandFactory.createCommand(obj[id]);
        command &&
          command.execute({
            json: obj[id],
            pageIndex,
            isDoublePageMode,
            interactiveObjectState,
            setInteractiveObjectState,
            interactiveObjectJSON,
            target
          });
        if (obj[id].contentType !== InteractiveObjectContentType.Submenu) {
          //firestore && setEventStatusToFirebase({ interactiveObjectEvent: { json: { ...obj[id], time: new Date().getTime() } } })
        }
        // } else {
        //   alert("此功能於未來推出，敬請期待唷 ❤")
        // }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [CommandFactory, interactiveObjectJSON, strategy]
  );

  const exportInteractiveObjectEventHandler = useCallback(async () => {
    readerDispatch({
      type: types.SET_INDICATOR_INFO,
      indicatorInfo: { isActive: true }
    });
    const pageIndices = [];
    if (isDoublePageMode) {
      pageIndices.push.apply(pageIndices, [pageIndex * 2, pageIndex * 2 + 1]);
    } else {
      pageIndices.push.apply(pageIndices, [pageIndex]);
    }
    await exportInteractiveObjects(pageIndices);
    readerDispatch({
      type: types.SET_INDICATOR_INFO,
      indicatorInfo: { isActive: false }
    });
  }, [exportInteractiveObjects, isDoublePageMode, pageIndex, readerDispatch]);

  const createAndEnterAnnotationEventHandler = useCallback(
    async ({
      annotationId,
      bookId,
      annotationType,
      annotations,
      name,
      pageIndex = 0,
      createNewAnnotation = false,
      callback
    }) => {
      if (!createNewAnnotation && !annotationId && !window.android && !window.ios) {
        createNewAnnotation = true;
        annotationId = uuid();
      }

      // if (annotationType === AnnotationType.GUEST) {
      //     const annotations = await readAnnotations({ bookId, annotationType });
      //     if (annotations.length > 0) {
      //         annotationId = annotations[0].id;
      //         createNewAnnotation = false;
      //     }
      // }

      if (createNewAnnotation) {
        await createTldrawAnnotation({
          id: annotationId,
          bookId,
          name,
          type: annotationType,
          pageIndex,
          annotations: annotations || {},
          isUpdate: false
        });
        readerDispatch({ type: types.SET_BOOK_PAGE_INDEX, pageIndex }); //從課本習作切換到從未建立過 annotation 的輔材時須重新設定 pageIndex 否則輔材可能會沒有和原 pageIndex 對應的頁面
        if (annotationType !== AnnotationType.GUEST) {
          syncClassPreparation(annotationId);
        }
      }

      if (annotationType === AnnotationType.ACTIVITY) {
        return EventBus.emit({
          event: createNewAnnotation
            ? ActivityEvent.CreatActivityEvent
            : ActivityEvent.EnterActivityEvent,
          payload: {
            activityId: annotationId,
            annotationType,
            bookId,
            annotations,
            name,
            callback
          }
        });
      }
      // enter
      annotationDispatch({
        type: types.UPDATE_ANNOTATION_INFO,
        annotationId,
        annotationType,
        name
      });
      callback && callback({ annotationId });
    },
    [annotationDispatch, createTldrawAnnotation, readerDispatch, syncClassPreparation]
  );

  const clickBookmarkEventHandler = useCallback(
    async ({ defaultPageIndex, bookId: targetBookId }) => {
      // const pageIndex = isDoublePageMode ? Math.floor(page / 2) : page;
      bookDispatch({type: types.SET_DEFAULT_BOOK_PAGE_INDEX, defaultPageIndex}); // 將 defaultPageIndex 存下，refreshReader 才知道接下來要進課本還是習作
      if (bookId === targetBookId) {

        let index = defaultPageIndex;
        if (catalog.length > 0) {
          const { bookmarkRecord } = await readTldrawAnnotationById({ id: annotationId });
          const {startPage, bookmarkIndex} = catalog.getBookmarkIndex(defaultPageIndex); // 更改 getBookmarkIndex 輸出的資料結構，以利在課習與輔材切換時能正確進入課本或習作

          if (bookmarkRecord && isExist(bookmarkRecord[bookmarkIndex])) {
            const record = bookmarkRecord[bookmarkIndex].index; // this value will from single page mode
            index = record;
          } else {
            const recordObj = Object.assign(bookmarkRecord || {}, { [bookmarkIndex]: { startPage, index } })
            await updateAnnotation(annotationId, {
              bookmarkRecord: recordObj
            });
          }
        }

        const pageIndex = isDoublePageMode ? Math.floor(index / 2) : index;
        //firestore && setEventStatusToFirebase({ pageIndex });
        setPageIndex({
          convertToSVG: true,
          pageIndex
        });
      } else {
        router.history.replace(`/loading?bookId=${targetBookId}`);
      }
    },
    [bookDispatch, bookId, catalog, isDoublePageMode, firestore, setPageIndex, readTldrawAnnotationById, annotationId, updateAnnotation, router.history]
  );

  const goBackCatalogEventHandler = useCallback(() => {
    let index = catalog.getCatalogIndex(isDoublePageMode, pageIndex);
    const newPageIndex = isDoublePageMode ? Math.floor(index / 2) : index;
    //firestore && setEventStatusToFirebase({ pageIndex: newPageIndex });
    setPageIndex({
      convertToSVG: true,
      pageIndex: newPageIndex
    });
  }, [firestore, catalog, isDoublePageMode, pageIndex, setPageIndex]);

  const clickOpenActivityEventHandler = useCallback(
    async ({ annotationId }) => {
      readerDispatch({
        type: types.SET_INDICATOR_INFO,
        indicatorInfo: { isActive: true }
      });
      let result = await readAnnotationById({ id: annotationId });
      createAndEnterAnnotationEventHandler({
        ...result,
        annotationId: null,
        annotationType: AnnotationType.ACTIVITY,
        callback: err =>
          readerDispatch({
            type: types.SET_INDICATOR_INFO,
            indicatorInfo: { isActive: false }
          })
      });
    },
    [createAndEnterAnnotationEventHandler, readAnnotationById, readerDispatch]
  );

  const OnMarkModeEventHandler = useCallback(async () => {
    readerDispatch({
      type: types.SET_MARK_MODE,
      isMarkModeShow: true
    });
    readerDispatch({
      type: types.SET_MARK_TOOLS_SHOW,
      isMarkToolsShow: false
    });
    await reimportSVG();
  }, [readerDispatch, reimportSVG]);

  const SetMarkToolsEnableEventHandler = useCallback(
    ({ enable }) => {
      readerDispatch({
        type: types.SET_MARK_TOOLS_SHOW,
        isMarkToolsShow: enable
      });
    },
    [readerDispatch]
  );

  const SelectMarkEventHandler = useCallback(
    ({ markObject }) => {
      SetMarkToolsEnableEventHandler({ enable: true });
      annotationDispatch({ type: types.SET_MARK_OBJECT, markObject });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [SetMarkToolsEnableEventHandler, annotationDispatch]
  );

  const SaveMarkEventHandler = useCallback(
    async ({ markObject, markId, pageIndex }) => {
      annotationDispatch({
        type: types.ADD_MARK_OBJECTS,
        markObject: { text: markObject.text, id: markId, pageIndex }
      });
      await updateAnnotation(annotationId, {
        marks: [...marks, { text: markObject.text, id: markId, pageIndex }],
        isDirty: true,
        updatedAt: Date.now()
      });
      strategy && strategy.syncAnnotation(annotationId);
    },
    [annotationDispatch, annotationId, marks, strategy, updateAnnotation]
  );

  const SaveReaderToolSettingsEventHandler = useCallback(
    async payload => {
      await createReaderToolSettings(bookId, payload);
    },
    [bookId, createReaderToolSettings]
  );

  const clickEraseAllEventHandler = useCallback(() => {
    confirmAlert({
      title: '全部刪除',
      message: '是否刪除當前物件',
      buttons: [
        {
          label: '確定',
          onClick: async () => {
            remoteAllCanvasObjects({ EventBusType });
            canvasDispatch({ type: types.CANVAS_ACTIVATE })
            canvasDispatch({ type: types.CANVAS_ERASE_ALL });


            setTimeout(async () => {
              //saveCanvasJSON();
              // console.log("canvasJSON", canvasJSON)
              const annotations = await saveCanvasJSON();
              EventBus.emit({
                event: ReaderToolsEvent.ClickDragEvent
              });
              readerDispatch({ type: types.SET_READER_TOOL_TYPE, readerToolType: ReaderToolType.Drag });
              canvasDispatch({ type: types.CANVAS_RESET_SVG });

              const canvasSVG = await convertJSONToSVG({
                annotations: annotations,
                keepCanvas: true,
                toSVG: true
              });
              canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });
              canvasDispatch({ type: types.CANVAS_INACTIVATE });
            }, 0);
          }
        },
        {
          label: '取消'
        }
      ],
      closeOnEscape: true,
      closeOnClickOutside: true
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remoteAllCanvasObjects, canvasDispatch, saveCanvasJSON, canvasJSON]);

  const ZoomToolEventHandler = useCallback(
    ({ type }) => {
      readerDispatch({ type: types.SET_READER_ZOOM_TYPE, zoomType: type });
    },
    [readerDispatch]
  );

  const SetReaderZoomEventHandler = useCallback(
    ({ type }) => {
      if (type === ReaderZoomType.OriginZoom) {
        readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInfos: null })
      }
      //firestore && setEventStatusToFirebase({ areaInfo: { type, timestamp: new Date().getTime() }, interactiveObjectEvent: { json: null }, pageIndex: null })
      ZoomToolEventHandler({ type })
    },
    [ZoomToolEventHandler, firestore, readerDispatch]
  );

  const areaZoomEventHandler = useCallback(
    ({ type, rect }) => {
      const zoomInfo = updateAreaZoomInfo(rect);
      readerDispatch({ type: types.SET_READER_ZOOM_TYPE, zoomType: type });
      //firestore && setEventStatusToFirebase({ areaInfo: { type, rect, zoomInfo, timestamp: new Date().getTime() } })
      readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects: null })
    },
    [updateAreaZoomInfo, readerDispatch, firestore]
  );

  const SetAreaZoomIntoEventHandler = useCallback(
    ({ type, rect, zoomInfo }) => {
      updateAreaZoomEventInfo({ type, rect, zoomInfo });
      readerDispatch({ type: types.SET_READER_ZOOM_TYPE, zoomType: type });
      readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects: null })
    },
    [readerDispatch, updateAreaZoomEventInfo]
  );

  const AreaZoomForPageButtonEventHandler = useCallback(
    ({ rect, areaZoomInteractiveObjects }) => {
      updateAreaZoomForPageButton(rect);
      areaZoomInteractiveObjects && readerDispatch({ type: types.SET_AREA_ZOOM_INTERACTIVE_OBJECTS, areaZoomInteractiveObjects });
    },
    [readerDispatch, updateAreaZoomForPageButton]
  );

  const clickFullWidthEventHandler = useCallback(
    ({ fullWidthMode }) => {
      EventBus.emit({
        event: ReaderToolsEvent.SaveReaderToolSettingsEvent,
        payload: { fullWidthMode }
      });
      readerDispatch({
        type: types.SET_FULL_WIDTH_INFO,
        fullWidthInfo: { mode: fullWidthMode }
      });
    },
    [readerDispatch]
  );

  const setReaderToolTypeEventHandler = useCallback(
    ({ readerToolType }) => {
      readerDispatch({ type: types.SET_READER_TOOL_TYPE, readerToolType });
      if (readerToolType === ReaderToolType.Mark) {
        canvasDispatch({ type: types.CANVAS_INACTIVATE });
      }
    },
    [canvasDispatch, readerDispatch]
  );

  const setMathToolsEventHandler = useCallback(
    ({ mathToolsInfo }) => {
      readerDispatch({ type: types.SET_MATH_TOOLS_INFO, mathToolsInfo });
    },
    [readerDispatch]
  );


  useEffect(() => updateFullWidthInfo(), [updateFullWidthInfo]);
  useEffect(() => updateReaderScaleInfo(), [updateReaderScaleInfo]);

  const setDrawAreaEventHandler = useCallback(
    ({ drawAreaInfo }) => {
      // readerDispatch({
      //   type: types.SET_READER_ZOOM_TYPE,
      //   zoomType: ReaderZoomType.OriginZoom
      // });
      readerDispatch({ type: types.TOGGLE_DRAW_AREA, drawAreaInfo });

      canvasDispatch({ type: types.CANVAS_ACTIVATE })
    },
    [canvasDispatch, readerDispatch]
  );

  const SaveAnnotationNameEventHandler = useCallback(
    async ({ annotationId, name }) => {
      const annotation = await updateAnnotation(annotationId, { name, updatedAt: Date.now() });
      if (annotation.type === AnnotationType.CLASS_PREPARATION) {
        syncClassPreparation(annotationId);
      }
    },
    [syncClassPreparation, updateAnnotation]
  );

  const DeleteAnnotationEventHandler = useCallback(
    async ({ id }) => {
      const annotation = await markAnnotationDeleted(id);
      if (annotation.type === AnnotationType.CLASS_PREPARATION) {
        syncClassPreparation(id);
      }
    },
    [markAnnotationDeleted, syncClassPreparation]
  );

  const CopyAnnotationEventHandler = useCallback(
    async ({ id, bookId, name, type, pageIndex, annotations, canvasSVGObjects, extendedContentAnnotation }) => {
      await createTldrawAnnotation({
        id: uuid(),
        bookId,
        name,
        type,
        pageIndex,
        annotations: annotations || {},
        canvasSVGObjects: canvasSVGObjects || [],
        extendedContentAnnotation: extendedContentAnnotation || [],
        isUpdate: false
      });
      if (type === AnnotationType.CLASS_PREPARATION) {
        syncClassPreparation(id);
      }
    },
    [createTldrawAnnotation, syncClassPreparation]
  );

  const GetLocalAnnotationEventHandler = useCallback(async ({ id, callback }) => {
    const annotation = await readAnnotationById({ id });
    callback(annotation.annotations);
  }, [readAnnotationById]);

  const clickOfflineReaderToolBoxEventHandler = useCallback(() => {

  }, []);

  const clickToolBoxEventHandler = useCallback(({ isToolboxShow }) => {
    readerDispatch({ type: types.SET_TOOLBOX_SHOW, isToolboxShow });
  }, [readerDispatch]);

  const toggleStampCollectionBoxShowHandler = useCallback(() => {
    readerDispatch({ type: types.TOGGLE_BUTTON_COLLECTION_BOX_SHOW });
  }, [readerDispatch])

  const SetObjectPointerStatusEventHandler = useCallback(({ objectPointerEventStatus }) => {
    localStorage.setItem('objectPointerEventStatus', objectPointerEventStatus);
    readerDispatch({
      type: types.SET_OBJECT_POINTER_EVENT_STATUS,
      objectPointerEventStatus
    });
  }, [readerDispatch])

  const setCanvasSVGObjectIdEventHandler = useCallback(({ canvasSVGObjectId }) => {
    readerDispatch({ type: types.SET_CANVAS_SVG_OBJECT_ID, canvasSVGObjectId });
    EventBus.emit({
      event: ReaderToolsEvent.ClickDragEvent
    });
    readerDispatch({ type: types.SET_READER_TOOL_TYPE, readerToolType: ReaderToolType.Drag });
  }, [readerDispatch])

  const CreateCourseInteractiveItemEventHandler = useCallback(
    async ({ painterMode, painterToolType, contentType, isNew = false }) => {
      //if (onCreateSVGObject) return;
      EventBus.emit({ event: ReaderToolsEvent.SetOnCreateSVGObject, payload: { onCreateSVGObject: true } })
      let { canvasSVGObjects } = await readAnnotationById({ id: annotationId });
      const id = isNew ? short_uuid() : canvasSVGObjectId ? canvasSVGObjectId : short_uuid()
      setCanvasSVGObjectIdEventHandler({ canvasSVGObjectId: id })
      let canvasSVGObject = canvasSVGObjects.find((obj) => obj.id === id);
      let object = {}
      if (contentType === 'Link') {
        object = {
          id, contentType, pageIndex, info: {
            list: [], color: "#fff"
          }
        }
      } else {
        object = { id, contentType, pageIndex, info: { text: "", color: "#FFE692" } }
      }


      if (!canvasSVGObjects) {
        canvasSVGObjects = []
      }
      if (!canvasSVGObject) {
        createCourseInteractiveItem({ id, contentType })
        canvasSVGObjects.push(object)
        const annotation = await updateAnnotation(annotationId, { canvasSVGObjects });
        canvasDispatch({
          type: types.CANVAS_CHANGE_PAINTER_MODE,
          painterMode,
          painterToolType
        });
      }
      
      window.isWhiteboardSave=false;
      EventBus.emit({
        eventBusType: EventBusType.ExtendedContent,
        event: CanvasEvent.CanvasFinishPaintingEvent,
        payload: { canvasSVGObjectId: id }
      });

      EventBus.emit({
        event: ReaderToolsEvent.ClickDragEvent
      });
      EventBus.emit({
        event: ReaderToolsEvent.SetReaderToolTypeEvent,
        payload: {
          readerToolType: ReaderToolType.Drag
        }
      });

    }, [setCanvasSVGObjectIdEventHandler, canvasSVGObjectId, createCourseInteractiveItem, pageIndex, readAnnotationById, annotationId, updateAnnotation, canvasDispatch])

  const ClickCanvasSVGObjectEventHandler = useCallback(async ({ annotationId, id, target }) => {
    if (!annotationId) { return; }
    const { canvasSVGObjects } = await readAnnotationById({ id: annotationId });
    if (!canvasSVGObjects) { return; }
    const canvasSVGObject = canvasSVGObjects.find((obj) => obj.id === id)
    if (!canvasSVGObject) { return; }

    const command = CanvasSVGCommandFactory.createCommand(canvasSVGObject);
    command &&
      readerDispatch({ type: types.SET_CANVAS_SVG_OBJECT_ID, canvasSVGObjectId: id });

    command.execute({
      canvasSVGObject,
      id,
      target
    });
  }, [CanvasSVGCommandFactory, readAnnotationById, readerDispatch])

  const SaveCanvasSVGObjectEventHandler = useCallback(async ({ annotationId, canvasSVGObject }) => {
    const { canvasSVGObjects } = await readAnnotationById({ id: annotationId });
    const newCanvasSVGObjects = canvasSVGObjects.map((obj) => {
      if (obj.id === canvasSVGObject.id) {
        return canvasSVGObject
      } else {
        return obj
      }
    })
    const annotation = await updateAnnotation(annotationId, { canvasSVGObjects: newCanvasSVGObjects });
    strategy && strategy.syncAnnotation(annotationId);
  }, [readAnnotationById, updateAnnotation, strategy])

  const DeleteCanvasSVGObjectInfoEventHandler = useCallback(async ({ objectIds }) => {
    const { canvasSVGObjects, extendedContentAnnotation } = await readAnnotationById({ id: annotationId });
    //undo redo 問題所以刪除不在此刪除，改到翻頁後才過濾後刪除
    const newCanvasSVGObjects = canvasSVGObjects && canvasSVGObjects.filter((obj) => {
      // if (objectIds.find((id) => id === obj.id)) {
      //   return false
      // }
      return obj;
    })

    const newExtendedContentAnnotation = extendedContentAnnotation && extendedContentAnnotation.filter((obj) => {
      // if (objectIds.find((id) => id === obj.id)) {
      //   return false
      // }
      return obj;
    })

    const annotation = await updateAnnotation(annotationId, {
      canvasSVGObjects: newCanvasSVGObjects,
      extendedContentAnnotation: newExtendedContentAnnotation,
    });
    strategy && strategy.syncAnnotation(annotationId);
  }, [strategy, annotationId, readAnnotationById, updateAnnotation])

  const SetProgressEventHander = useCallback(({ progress }) => {
    readerDispatch({ type: types.SET_PROGRESS, progress });
  }, [readerDispatch])

  const closePannelEventHandler = useCallback(({ pannlType }) => {
    //firestore && setEventStatusToFirebase({ interactiveObjectEvent: { json: null } })
    //firestore && setEventStatusToFirebase({ closePannelEvent: { pannlType } })
  }, [firestore])

  const setSyncCameraEvnetHandler = useCallback(async ({ isOpen = null }) => {
    await checkIsOnline()
    const isShow = isOpen === null ? !isSynchronousCamera : isOpen;
    //firestore && setEventStatusToFirebase({ openSyncCamera: { json: isShow } });
    readerDispatch({ type: types.SET_SYNCHRONOUS_CAMERA, isSynchronousCamera: isShow });
  }, [checkIsOnline, isSynchronousCamera, firestore, readerDispatch])

  const SetFirebaseInfoEventHandler = useCallback((payload) => {
    //firestore && setSyncCanvasTargetId(payload)
  }, [firestore])

  const SetStampCollectionEventEventHandler = useCallback(({ isChecked, id }) => {
    //firestore && setEventStatusToFirebase({ stampCollection: { isChecked, id } })
  }, [firestore])

  const setCourseInfoEventHandler = useCallback((payload) => {
    courseDispatch({ type: types.SET_COURSE_INFO, courseInfo: payload })
  }, [courseDispatch])

  const setSyncOnConnectedEvnetHandler = useCallback(({ onConnected }) => {
    courseDispatch({ type: types.SET_ONCONNECTED, onConnected })
  }, [courseDispatch])

  const setOnCreateSVGObjectHandler = useCallback(({ onCreateSVGObject }) => {
    readerDispatch({ type: types.SET_ON_CREATE_SVG_OBJECT, onCreateSVGObject })
  }, [readerDispatch])

  const setFabricSVGElementHandler = useCallback(({ fabricSVGElement }) => {
    readerDispatch({ type: types.SET_FABRIC_SVG_ELEMENT, fabricSVGElement });
  }, [readerDispatch])

  const setFabricObjectTargetInfoHandler = useCallback(({ fabricObjectTargetInfo }) => {
    readerDispatch({ type: types.SET_FABRIC_OBJECT_TARGET, fabricObjectTargetInfo });
  }, [readerDispatch])

  const undoEventHandler = useCallback(async () => {
    // console.log("undoAnnotations", undoAnnotations);
    if (undoAnnotations.undoCount === 0 || !undoAnnotations) {
      EventBus.emit({
        event: ReaderToolsEvent.SetSnackbarMessage,
        payload: { snackbarMessage: '已達回復上限' },
      });
      return
    };
    let { undoCount } = undoAnnotations;
    // console.log('undoCount before: ', undoCount);
    undoCount -- ;
    const prevPage = pageIndex * 2;
    const nextPage = pageIndex * 2 + 1;
    const canvasJson = isDoublePageMode ? {
      [prevPage]: undoAnnotations[prevPage][undoCount],
      [nextPage]: undoAnnotations[nextPage][undoCount]
    } : {
      [pageIndex]: undoAnnotations[pageIndex][undoCount]
    };
    // console.log('undoCount after: ', undoCount);
    await saveCanvasJSON({ undoCount, canvasJson });
    canvasDispatch({ type: types.CANVAS_ACTIVATE });
    const canvasSVG = await convertJSONToSVG({
      annotations: canvasJson,
      keepCanvas: true,
      toSVG: true
    });
    canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });
    if (readerToolType === ReaderToolType.Drag) {
      canvasDispatch({ type: types.CANVAS_INACTIVATE });
    }
    

    // const targetPageIndex = (pageIndex * (isDoublePageMode ? 2 : 1))
    // if (!undoAnnotations || !undoAnnotations[targetPageIndex]) return;
    // let undoCount = undoAnnotations[targetPageIndex].undoCount - 1
    // if (undoCount < 0) return;
    // const annotations = undoAnnotations[targetPageIndex].canvasJsonList[undoCount]
    // const annotation = isDoublePageMode ? annotations : annotations[pageIndex]
    // console.log("annotation", annotation)
    // await saveCanvasJSON({ undoCount, canvasJson: annotation })
    // const canvasSVG = await convertJSONToSVG({
    //   annotations: annotation,
    //   keepCanvas: true,
    //   toSVG: true
    // });
    // canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });
  }, [canvasDispatch, convertJSONToSVG, isDoublePageMode, pageIndex, readerToolType, saveCanvasJSON, undoAnnotations])

  const redoEventHandler = useCallback(async () => {
    // console.log("redoAnnotations", undoAnnotations)
    if (undoAnnotations.undoCount === 5 || !undoAnnotations || undoAnnotations.undoCount + 1 > undoAnnotations.maxCount ) {
      EventBus.emit({
        event: ReaderToolsEvent.SetSnackbarMessage,
        payload: { snackbarMessage: '已達回復上限' },
      });
      return
    };
    let { undoCount } = undoAnnotations;
    // console.log('undoCount before: ', undoCount);
    undoCount ++ ;
    const prevPage = pageIndex * 2;
    const nextPage = pageIndex * 2 + 1;
    const canvasJson = isDoublePageMode ? {
      [prevPage]: undoAnnotations[prevPage][undoCount],
      [nextPage]: undoAnnotations[nextPage][undoCount]
    } : {
      [pageIndex]: undoAnnotations[pageIndex][undoCount]
    };
    // console.log('undoCount canvasJson: ', canvasJson);
    // console.log('undoCount after: ', undoCount);
    await saveCanvasJSON({ undoCount, canvasJson });
    canvasDispatch({ type: types.CANVAS_ACTIVATE });
    const canvasSVG = await convertJSONToSVG({
      annotations: canvasJson,
      keepCanvas: true,
      toSVG: true
    });
    canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });
    if (readerToolType === ReaderToolType.Drag) {
      canvasDispatch({ type: types.CANVAS_INACTIVATE });
    }

    // const targetPageIndex = (pageIndex * (isDoublePageMode ? 2 : 1))
    // if (!undoAnnotations || !undoAnnotations[targetPageIndex]) return;
    // let undoCount = undoAnnotations[targetPageIndex].undoCount + 1
    // if (undoCount > undoAnnotations[targetPageIndex].canvasJsonList.length - 1) {
    //   return;
    // }
    // const annotations = undoAnnotations[targetPageIndex].canvasJsonList[undoCount]
    // const annotation = isDoublePageMode ? annotations : annotations[pageIndex]
    // await saveCanvasJSON({ undoCount, canvasJson: annotation })
    // const canvasSVG = await convertJSONToSVG({
    //   annotations: annotation,
    //   keepCanvas: true,
    //   toSVG: true
    // });
    // canvasDispatch({ type: types.CANVAS_IMPORT_SVG, canvasSVG });
  }, [canvasDispatch, convertJSONToSVG, isDoublePageMode, pageIndex, readerToolType, saveCanvasJSON, undoAnnotations])

  const togglePaiNoteRecordHandler = useCallback(() => {
    readerDispatch({
      type: types.TOGGLE_PAINOTE_RECORD
    });
  }, [readerDispatch])

  const selectNoteEventHandler = useCallback(({ note }) => {
    readerDispatch({
      type: types.SET_PAINOTE_SELECTEDNOTE,
      paiNoteSelectedNote: note
    })
  }, [readerDispatch])

  useEvent({ event: ReaderToolsEvent.UndoEvent }, undoEventHandler);
  useEvent({ event: ReaderToolsEvent.RedoEvent }, redoEventHandler);
  const setSnackbarMessageHandler = useCallback(({ snackbarMessage }) => {
    readerDispatch({ type: types.SET_SNACKBAR_MESSAGE, snackbarMessage });
  }, [readerDispatch])

  // const syncAnnotationToCloud = useCallback(async() => {
  //   const isOnline = await checkIsOnline();
  //       if (isOnline) {
  //         strategy && strategy.syncAnnotation(annotationId);
  //       } else {
  //           readerDispatch({
  //               type: types.SET_ONLINE_DIALOG,
  //               isOnLineDialog: true,
  //           });
  //       }
  // }, [checkIsOnline, strategy, annotationId, readerDispatch])

  useEvent({ event: ReaderToolsEvent.SetFabricObjectTargetInfo }, setFabricObjectTargetInfoHandler);
  useEvent({ event: ReaderToolsEvent.SetFabricSVGElement }, setFabricSVGElementHandler);
  useEvent({ event: ReaderToolsEvent.SetOnCreateSVGObject }, setOnCreateSVGObjectHandler);
  useEvent({ event: ReaderToolsEvent.SetCourseInfoEvent }, setCourseInfoEventHandler);

  useEvent({ event: ReaderEvent.SetStampCollectionEventEvent }, SetStampCollectionEventEventHandler);
  useEvent({ event: ReaderToolsEvent.SetFirebaseInfoEvent }, SetFirebaseInfoEventHandler);
  useEvent({ event: ReaderToolsEvent.SetSyncCameraEvent }, setSyncCameraEvnetHandler);
  useEvent({ event: ReaderToolsEvent.SetSyncOnConnected }, setSyncOnConnectedEvnetHandler);
  useEvent({ event: ReaderToolsEvent.SetSnackbarMessage }, setSnackbarMessageHandler);
  useEvent(
    { event: ReaderToolsEvent.ClosePannelEvent },
    closePannelEventHandler
  );

  useEvent(
    [
      { event: ReaderEvent.SetPageIndexEvent },
      { event: ReaderEvent.ClickBookThumbnailEvent }
    ],
    setPageIndexHandler
  );

  useEvent(
    { event: ReaderEvent.ClickPreviousPageEvent },
    previousPageEventHandler
  );
  useEvent({ event: ReaderEvent.ClickNextPageEvent }, nextPageEventHandler);
  useEvent({ event: ReaderEvent.DrawAreaEvent }, setDrawAreaEventHandler);

  useEvent(
    { event: ReaderEvent.SaveAnnotationNameEvent },
    SaveAnnotationNameEventHandler
  );
  useEvent(
    { event: ReaderEvent.DeleteAnnotationEvent },
    DeleteAnnotationEventHandler
  );
  useEvent(
    { event: ReaderEvent.CopyAnnotationEvent },
    CopyAnnotationEventHandler
  );
  useEvent(
    { event: ReaderEvent.SetProgressEvent },
    SetProgressEventHander
  );

  useEvent(
    { event: ReaderEvent.GetLocalAnnotationEvent },
    GetLocalAnnotationEventHandler
  );

  useEvent(
    { event: ReaderToolsEvent.TogglePageModeEvent },
    togglePageModeHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SetReaderToolHeight },
    setReaderToolHeightHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SetReaderToolsEvent },
    setReaderToolsHandler
  );
  useEvent({ event: ReaderToolsEvent.ClickDragEvent }, clickDragHandler);
  useEvent(
    { event: ReaderToolsEvent.ChangeStampStatusEvent },
    changeStampStatusHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ToggleSideBarEvent },
    toggleSideBarHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ToggleFullScreen },
    toggleFullScreenHandler
  );
  useEvent(
    { event: ReaderToolsEvent.TogglePageSearcherEvent },
    togglePageSearcherHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ClickReaderOptionPanelEvent },
    clickReaderOptionPanelHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ClickStampPanelEvent },
    clickStampPanelHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SetObjectPropertyEvent },
    setObjectPropertyHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ToggleBookmarkEvent },
    toggleBookmarkHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ClickOpenActivityEvent },
    clickOpenActivityEventHandler
  );
  useEvent({ event: ReaderToolsEvent.OnMarkModeEvent }, OnMarkModeEventHandler);
  useEvent({ event: ReaderToolsEvent.SelectMarkEvent }, SelectMarkEventHandler);
  useEvent(
    { event: ReaderToolsEvent.SetMarkToolsEnableEvent },
    SetMarkToolsEnableEventHandler
  );
  useEvent({ event: ReaderToolsEvent.SaveMarkEvent }, SaveMarkEventHandler);
  useEvent(
    { event: ReaderToolsEvent.SetReaderZoomEvent },
    SetReaderZoomEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ZoomToolEvent },
    ZoomToolEventHandler
  );
  useEvent({ event: ReaderEvent.AreaZoomEvent }, areaZoomEventHandler);
  useEvent({ event: ReaderEvent.SetAreaZoomIntoEvent }, SetAreaZoomIntoEventHandler);
  useEvent({ event: ReaderEvent.ClickCanvasSVGObjectEvent }, ClickCanvasSVGObjectEventHandler);
  useEvent({ event: ReaderEvent.SaveCanvasSVGObjectEvent }, SaveCanvasSVGObjectEventHandler);
  useEvent({ event: ReaderEvent.DeleteCanvasSVGObjectInfoEvent }, DeleteCanvasSVGObjectInfoEventHandler);
  useEvent(
    { event: ReaderEvent.AreaZoomForPageButtonEvent },
    AreaZoomForPageButtonEventHandler
  );

  useEvent({ event: ReaderEvent.SetCanvasSVGObjectIdEvent }, setCanvasSVGObjectIdEventHandler);

  useEvent(
    { event: ReaderToolsEvent.ClickFullWidthEvent },
    clickFullWidthEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SaveReaderToolSettingsEvent },
    SaveReaderToolSettingsEventHandler
  );
  useEvent(
    [
      { event: ReaderToolsEvent.ClickSelectEvent },
      { event: ReaderToolsEvent.ClickPainterEvent },
      { event: ReaderToolsEvent.ClickEraserEvent },
      { event: ReaderToolsEvent.ClickInsertImageEvent },
      { event: ReaderToolsEvent.ClickInsertTextEvent }
    ],
    changePainterModeHandler
  );

  useEvent(
    { event: PainterEvent.LoadReaderToolSettingsEvent },
    LoadReaderToolSettingsHandler
  );
  useEvent(
    { event: PainterEvent.ChangeBrushColorEvent },
    changeBrushColorHandler
  );
  useEvent(
    { event: PainterEvent.ChangeBrushWidthEvent },
    changeBrushWidthHandler
  );
  useEvent(
    { event: PainterEvent.ChangeBrushTypeEvent },
    changeBrushTypeHandler
  );
  useEvent(
    { event: PainterEvent.ChangeShapeFillTypeEvent },
    changeShapeFillTypeHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextColorEvent },
    changeTextColorHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextFontSizeEvent },
    changeTextFontSizeHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextFontStyleEvent },
    changeTextFontStyleHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextFontWeightEvent },
    changeTextFontWeightHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextUnderlineEvent },
    changeTextUnderlineHandler
  );
  useEvent(
    { event: PainterEvent.ChangeTextBgColorEvent },
    changeTextBgColorHandler
  );
  useEvent({ event: PainterEvent.ChangelineTypeEvent }, changelineTypeHandler);
  useEvent(
    { event: PainterEvent.ChangePainterTypeEvent },
    changePainterTypeHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ChangeReaderToolStyleEvent },
    changeReaderToolStyleHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ChangeReaderToolDirectionEvent },
    changeReaderToolDirectionHandler
  );
  useEvent({ event: ReaderEvent.RefreshCanvasEvent }, RefreshCanvasHandler);
  useEvent(
    { event: InteractiveObjectEvent.ClickInteractiveObjectEvent },
    clickInteractiveObjectHandler
  );
  useEvent(
    { event: ReaderToolsEvent.PlayExpression },
    playExpressionEventHandler
  );
  useEvent(
    { event: InteractiveObjectEvent.ExportInteractiveObjectEvent },
    exportInteractiveObjectEventHandler
  );
  useEvent(
    { event: ReaderEvent.CreateAndEnterAnnotationEvent },
    createAndEnterAnnotationEventHandler
  );
  useEvent(
    { event: ReaderEvent.ClickBookmarkEvent },
    clickBookmarkEventHandler
  );
  useEvent(
    { event: ReaderEvent.GoBackCatalogEvent },
    goBackCatalogEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ClickEraseAllEvent },
    clickEraseAllEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.SetReaderToolTypeEvent },
    setReaderToolTypeEventHandler
  );
  useEvent(
    { event: ReaderToolsEvent.ClickOfflineReaderToolBoxEvent },
    clickOfflineReaderToolBoxEventHandler,
  );
  useEvent(
    { event: ReaderToolsEvent.ClickReaderToolBoxEvent },
    clickToolBoxEventHandler
  )
  useEvent(
    { event: ReaderToolsEvent.ToggleStampCollectionBoxShowEvent },
    toggleStampCollectionBoxShowHandler
  )
  useEvent(
    { event: ReaderToolsEvent.SetObjectPointerStatusEvent },
    SetObjectPointerStatusEventHandler
  )
  useEvent(
    { event: ReaderToolsEvent.CreateCourseInteractiveItemEvent },
    CreateCourseInteractiveItemEventHandler
  );
  useEvent({ event: ReaderToolsEvent.ClickMathToolsEvent }, setMathToolsEventHandler)
  
  useEvent({ event: ReaderToolsEvent.TogglePaiNoteRecordEvent }, togglePaiNoteRecordHandler)

  useEvent({ event: ReaderToolsEvent.SelectNoteEvent }, selectNoteEventHandler)
  // useEvent({ event: ReaderToolsEvent.SyncAnnotationToCloud }, syncAnnotationToCloud)
};
