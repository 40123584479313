import React, { useState } from "react";
import { Rectangle2d, ShapeUtil, stopEventPropagation } from "tldraw";
import MathJax from "react-mathjax2";
import Repository from "repositories/Repository";
import { useStore, StoreTypes } from "context";
import styles from "./index.module.scss";
import { BookFlipType } from "constants/flipTypes";

const { ExtendedResourceRepository } = Repository;

export class ExtendedContent extends ShapeUtil {
  static type = "extendedContent";
  getDefaultProps() {
    return {
      w: 500,
      h: 500,
    };
  }

  canEdit = () => false;
  canResize = () => false;
  isAspectRatioLocked = () => false;

  getGeometry(shape) {
    return new Rectangle2d({
      width: shape.props.w,
      height: shape.props.h,
      isFilled: true,
    });
  }

  component(shape) {
    const [{ extendedContentChild }] = useStore(StoreTypes.extendedContent);
    const [clickable, setClickable] = useState(false)
    const style = {
      ...shape.meta.bookFlipType === BookFlipType.RIGHT_TO_LEFT && { height: shape.props.h},  
      ...shape.meta.bookFlipType === BookFlipType.LEFT_TO_RIGHT && { width: shape.props.w },
      ...extendedContentChild.props.extendedResourceStyle && extendedContentChild.props.extendedResourceStyle.match("WordSection") != null && {
        direction: "rtl",
      },
    }

    const handlePointOver = (e) => {
      setClickable(window.getComputedStyle(e.target)['cursor'] === 'pointer')
    }

    return (
      <div className={styles.extendedContent} style={style} id="tl-extendedContent" onPointerOver={handlePointOver} onPointerDown={clickable ? stopEventPropagation : null}>
        <MathJax.Context
          id="target"
          script={`${
            window.electron || window.ios || window.android ? "" : "/"
          }${ExtendedResourceRepository.getMathJaxPath()}?config=TeX-MML-AM_CHTML`}
        >
          <MathJax.Text text={extendedContentChild} />
        </MathJax.Context>
      </div>
    );
  }
}
